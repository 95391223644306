import { LUMPSUM_UNITS, type Product, type Unit } from "@/types/product";

export function getAmountAndUnitForNewOfferPosition(
  boqAmount: number | null,
  boqUnit: Unit | null,
  product: Product,
): [amount: number | null, unit: Unit] {
  const unit = getBestProductUnit(product, boqUnit);
  const amount = getAmountForNewOfferPosition(
    boqAmount,
    boqUnit,
    unit,
    product,
  );
  return [amount, unit];
}

function getBestProductUnit(product: Product, boqUnit: Unit | null): Unit {
  if (boqUnit && product.alternativeUnits.includes(boqUnit)) return boqUnit;
  return product.unit;
}

function getAmountForNewOfferPosition(
  boqAmount: number | null,
  boqUnit: Unit | null,
  unit: Unit,
  product: Product,
): number | null {
  if (!boqAmount || !boqUnit) return null;

  if (LUMPSUM_UNITS.includes(unit)) {
    // "PAUSCHAL" - always has amount 1
    return 1;
  }

  return convertAmount(boqAmount, boqUnit, unit, product);
}

export function convertAmount(
  amount: number,
  from_unit: Unit,
  to_unit: Unit,
  product: Product,
): number | null {
  if (from_unit === to_unit) {
    return amount;
  }

  const baseAmount = convertToBaseAmount(amount, from_unit, product);
  if (!baseAmount || to_unit === product.unit) {
    return baseAmount;
  }

  const convFromBase = product.conversions[to_unit.toLowerCase()];
  if (!convFromBase) {
    return null;
  }
  return (baseAmount * convFromBase.denominator) / convFromBase.numerator;
}

function convertToBaseAmount(
  amount: number,
  unit: Unit,
  product: Product,
): number | null {
  if (unit === product.unit) {
    return amount;
  }

  const convToBase = product.conversions[unit.toLowerCase()];
  if (!convToBase) {
    return null;
  }
  return (amount * convToBase.numerator) / convToBase.denominator;
}

export function getProductPriceInUnit(
  product: Product,
  priceName: "listPrice" | "purchasePrice",
  unit: Unit,
): number | null {
  return convertPrice(product[priceName], product.unit, unit, product, priceName);
}

export function convertPrice(
  price: number | null,
  fromUnit: Unit | null,
  toUnit: Unit,
  product: Product,
  priceName: "listPrice" | "purchasePrice",
): number | null {
  if (fromUnit === toUnit) {
    return price;
  }

  if (!price || !fromUnit) {
    if (toUnit === product.unit) {
      return product[priceName];
    }
    return null;
  }

  const priceInBaseUnit = convertToBaseUnitPrice(price, fromUnit, product);
  if (priceInBaseUnit !== null && toUnit === product.unit) {
    // target unit is base unit, so we are already done
    return priceInBaseUnit;
  }
  const conversionData = product.conversions[toUnit.toLowerCase()];
  if (priceInBaseUnit !== null && conversionData) {
    return (
      (priceInBaseUnit * conversionData.numerator) / conversionData.denominator
    );
  }

  return null;
}

function convertToBaseUnitPrice(
  price: number,
  unit: Unit,
  product: Product,
): number | null {
  if (unit === product.unit) {
    return price;
  }

  const conversionData = product.conversions[unit.toLowerCase()];
  if (!conversionData) {
    return null;
  }
  return (price * conversionData.denominator) / conversionData.numerator;
}
