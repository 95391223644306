import { ref, watch } from 'vue'
import debounce from 'lodash/debounce'

/**
 * Use this composable to manage an internal, debounced modelValue.
 * 
 * @param props An object with at least { modelValue: any; debounceMs?: number }
 * @param emit A function to emit events (usually from defineEmits in the parent component)
 * @param options Optional config (e.g., defaultDebounceMs)
 */
export function useDebouncedModel<T>(
  props: { modelValue: T; debounceMs?: number },
  emit: (event: 'update:modelValue', value: T) => void,
  options?: { defaultDebounceMs?: number }
) {
  const defaultDelay = options?.defaultDebounceMs ?? 500

  // Local copy of modelValue for immediate UI updates
  const internalValue = ref(props.modelValue)

  // Create a debounced function for emitting model updates
  const debouncedEmit = debounce(
    (value: T) => {
      emit('update:modelValue', value)
    },
    props.debounceMs ?? defaultDelay
  )

  // Whenever we receive a new value from the base input, update internalValue
  // and schedule the debounced emit
  function handleLocalInput(value: T) {
    internalValue.value = value
    debouncedEmit(value)
  }

  // Sync internalValue if the parent changes modelValue externally
  watch(
    () => props.modelValue,
    (newVal) => {
      internalValue.value = newVal
    }
  )

  // If you need to force-flush the debounced value immediately (e.g., on blur),
  // you can call this before the component finishes.
  function flushValue() {
    // Cancel the current debounce, then emit immediately
    debouncedEmit.cancel()
    emit('update:modelValue', internalValue.value)
  }

  return {
    internalValue,
    handleLocalInput,
    flushValue,
  }
}