<template>
  <div class="fit column no-wrap">
    <div class="row items-start justify-start gap-x-sm gap-y-sm">
      <q-chip
        v-for="manufacturer in preferredManufacturers"
        class="q-ma-none"
        :key="manufacturer.id"
        :label="manufacturer.name"
        size="sm"
      />
    </div>
    <q-space />
    <div>
      <q-btn dense flat color="primary" @click="openManufacturerSelectDialog">
        {{ $t("inquiryPage.preferredManufacturers.select") }}
      </q-btn>
    </div>
  </div>
</template>

<script setup lang="ts">
import ManufacturerSelectDialog from "@/components/ManufacturerSelectDialog.vue";
import { useCurrentInquiryStore } from "@/stores/currentInquiry";
import { storeToRefs } from "pinia";
import { useQuasar } from "quasar";
import { computed } from "vue";

const store = useCurrentInquiryStore();
const { inquiry } = storeToRefs(store);

const q = useQuasar();

const preferredManufacturers = computed(() => {
  const manufacturers = inquiry.value?.preferredManufacturers ?? [];
  manufacturers.sort((a, b) => a.name.localeCompare(b.name));
  return manufacturers;
});

function openManufacturerSelectDialog() {
  q.dialog({
    component: ManufacturerSelectDialog,
    componentProps: {
      preferredManufacturers: preferredManufacturers.value,
    },
  });
}
</script>
