<template>
  <AsyncMultiSelectMenu
    :findOptions="findSuppliers"
    :selectedOptions="selectedSuppliers"
    :partiallySelectedOptions="[]"
    :searchPlaceholder="$t('supplierSelectMenu.searchPlaceholder')"
    :labelFn="(option: ShortSupplier) => option.name"
    :filterFn="filterSuppliers"
    idValue="id"
    :preloadOptions="[]"
    @select="selectSupplier"
    @unselect="unselectSupplier"
  >
    <template #option-label="{ option }">
      <supplier-label :supplier="option" />
    </template>
  </AsyncMultiSelectMenu>
</template>

<script setup lang="ts">
import {
  findSuppliers as apiFindSuppliers,
  getSupplier as apiGetSupplier,
} from "@/api/supplier";
import AsyncMultiSelectMenu from "@/components/SelectMenus/AsyncMultiSelectMenu.vue";
import SupplierLabel from "@/components/SupplierLabel.vue";
import { useRouteParams } from "@/composables/useRouteParams";
import type { Supplier } from "@/types/supplier";
import { computed, ref, watch } from "vue";

const props = defineProps<{
  selectedSupplierIds: number[];
}>();

const emit = defineEmits<{
  "update:selectedSupplierIds": [supplierIds: number[]];
}>();

type ShortSupplier = Pick<Supplier, "id" | "name" | "externalId" | "town">;

const { organizationId } = useRouteParams();

const cachedSuppliers = ref<Record<number, ShortSupplier>>({});

const selectedSuppliers = computed(() =>
  props.selectedSupplierIds.map((id) => cachedSuppliers.value[id])
);

// Populate cache with selected suppliers
watch(
  () => props.selectedSupplierIds,
  async (ids) => {
    for (const id of ids) {
      if (cachedSuppliers.value[id]) continue;

      cachedSuppliers.value[id] = await apiGetSupplier(
        organizationId.value,
        id
      );
    }
  },
  { immediate: true }
);

function filterSuppliers(supplier: ShortSupplier, searchText: string) {
  return (
    supplier.name.toLowerCase().includes(searchText.toLowerCase()) ||
    supplier.externalId?.toLowerCase().includes(searchText.toLowerCase())
  );
}

async function findSuppliers(searchText: string) {
  if (!organizationId.value) return [];

  return await apiFindSuppliers(organizationId.value, searchText);
}

async function selectSupplier(supplier: ShortSupplier) {
  if (props.selectedSupplierIds.includes(supplier.id)) return;
  cachedSuppliers.value[supplier.id] = supplier;
  emit("update:selectedSupplierIds", [
    ...props.selectedSupplierIds,
    supplier.id,
  ]);
}

async function unselectSupplier(supplier: ShortSupplier) {
  emit(
    "update:selectedSupplierIds",
    props.selectedSupplierIds.filter((id) => id !== supplier.id)
  );
}
</script>
