<template>
  <div
    class="row items-center justify-end full-width q-py-xs gap-sm total-price-row"
  >
    <div
      v-if="organization?.useGrossMargins"
      class="margin-display row no-wrap items-center"
    >
      <q-space />
      <div class="text-neutral-7 ellipsis">
        {{ totalMargin ? totalMargin.toFixed(1) : "-" }} %
      </div>
      <q-tooltip :delay="1000">
        {{ $t("inquiryPositionsPage.offerPositionGroup.totalMargin") }}
      </q-tooltip>
    </div>
    <div class="total-price-string text-right">
      {{ totalPriceString }}
      <q-tooltip :delay="1000">
        {{ $t("inquiryPositionsPage.offerPositionGroup.totalPrice") }}
      </q-tooltip>
    </div>
  </div>
</template>

<script setup lang="ts">
import { DEFAULT_CURRENCY } from "@/config/defaults";
import { useCurrentOrganizationStore } from "@/stores/currentOrganization";
import type { OfferPosition } from "@/types/offerPosition";
import {
  calculateGroupTotalPrice,
  calculateGroupTotalMargin,
} from "@/utils/pricing";
import { storeToRefs } from "pinia";
import { computed } from "vue";
import { useI18n } from "vue-i18n";

const { locale } = useI18n();

const props = defineProps<{
  positions: OfferPosition[];
}>();

const { organization } = storeToRefs(useCurrentOrganizationStore());

const totalPriceString = computed(() => {
  const relevantPositions = props.positions.filter(
    (p) => p.amount && p.unitPrice && !p.isAlternative
  );

  if (relevantPositions.length === 0) {
    return "-";
  }

  const totalPrice = calculateGroupTotalPrice(relevantPositions);
  if (totalPrice === null) {
    return "-";
  }
  return totalPrice.toLocaleString(locale.value, {
    style: "currency",
    currency: organization.value?.details.currency ?? DEFAULT_CURRENCY,
  });
});

const totalMargin = computed(() => {
  return calculateGroupTotalMargin(props.positions);
});
</script>

<style scoped lang="scss">
.total-price-row {
  padding-right: 49px;
}

.total-price-string {
  // align with price display in offer positions
  width: $price-column-width;
}

.margin-display {
  width: $discount-margin-input-width;
  padding: 0 4px;
}
</style>
