<template>
  <q-menu
    :offset="[0, 4]"
    @keydown.escape.stop="menuRef?.hide()"
    @keydown.stop
    ref="menuRef"
  >
    <async-multi-select
      v-bind="props"
      @select="$emit('select', $event)"
      @unselect="$emit('unselect', $event)"
    >
      <template #option="{ option }" v-if="$slots['option']">
        <slot name="option" :option="option" />
      </template>
      <template #option-label="{ option }" v-if="$slots['option-label']">
        <slot name="option-label" :option="option" />
      </template>
      <template #option-after="{ option }" v-if="$slots['option-after']">
        <slot name="option-after" :option="option" />
      </template>
      <template #after v-if="$slots.after">
        <slot name="after" />
      </template>
    </async-multi-select>
  </q-menu>
</template>

<script
  setup
  lang="ts"
  generic="
    IdValue extends string,
    Option extends { [key in IdValue]: string | number }
  "
>
import { ref } from "vue";
import AsyncMultiSelect from "./AsyncMultiSelect.vue";
import type { QMenu } from "quasar";

const props = withDefaults(
  defineProps<{
    selectedOptions: Option[];
    partiallySelectedOptions: Option[];
    searchPlaceholder: string;
    idValue: IdValue;
    preloadOptions: Option[];
    findOptions: (searchText: string) => Promise<Option[]>;
    filterFn: (option: Option, searchText: string) => boolean;
    labelFn: (option: Option) => string;
    maxOptionsDisplayed?: number;
  }>(),
  { maxOptionsDisplayed: 10 },
);

defineEmits<{
  select: [option: Option];
  unselect: [option: Option];
}>();

const menuRef = ref<typeof QMenu | null>(null);
</script>
