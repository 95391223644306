<template>
  <q-item
    :key="quotationFile.id"
    :clickable="!quotationFile.temporaryId"
    @click.stop.prevent="
      quotationFile.temporaryId
        ? undefined
        : downloadQuotationFile(quotationFile.id)
    "
  >
    <q-item-section>
      <q-item-label class="row no-wrap items-center">
        <q-icon name="sym_r_draft" class="q-mr-sm" color="neutral-8" />
        <filename-display :filename="quotationFile.filename" />
      </q-item-label>
    </q-item-section>
    <q-item-section side right>
      <div v-if="!quotationFile.temporaryId">
        <q-btn
          flat
          dense
          size="sm"
          icon="sym_r_edit"
          @click.stop.prevent="showRenamePopup = true"
        />
        <q-btn
          flat
          dense
          size="sm"
          icon="sym_r_delete"
          @click.stop.prevent="onRemoveQuotationFile()"
        />
      </div>
      <q-circular-progress
        v-else
        indeterminate
        size="16px"
        color="neutral-7"
        class="q-mx-xs"
      />
    </q-item-section>
    <q-menu v-model="showRenamePopup" no-parent-event cover @click.stop>
      <q-list dense>
        <q-item>
          <q-item-section>
            <q-input
              dense
              borderless
              autofocus
              v-autoselect="selectFileStem"
              v-model="newFilename"
              class="q-small-input"
              @keyup.enter="onRenameQuotationFile"
              @keyup.esc="closeRenamePopup"
              @keyup.stop
            />
          </q-item-section>
          <q-item-section side right>
            <div class="row no-wrap items-center">
              <q-btn
                flat
                dense
                size="sm"
                icon="sym_r_close"
                @click.stop.prevent="closeRenamePopup"
                :disable="isRenaming"
              />
              <q-btn
                flat
                dense
                size="sm"
                icon="sym_r_check"
                @click.stop.prevent="onRenameQuotationFile"
                :loading="isRenaming"
              />
            </div>
          </q-item-section>
        </q-item>
      </q-list>
    </q-menu>
  </q-item>
</template>

<script setup lang="ts">
import FilenameDisplay from "@/components/FilenameDisplay.vue";
import { MAX_UPLOADED_FILE_NAME_LENGTH } from "@/config/constants";
import { useCurrentInquiryStore } from "@/stores/currentInquiry";
import type { QuotationFile } from "@/types/quotation";
import { useQuasar } from "quasar";
import { ref, watch } from "vue";
import { useI18n } from "vue-i18n";

const props = defineProps<{
  quotationFile: QuotationFile;
}>();

const q = useQuasar();
const { t } = useI18n();
const showRenamePopup = ref(false);
const newFilename = ref(props.quotationFile.filename);
const isRenaming = ref(false);
watch(
  () => props.quotationFile.filename,
  (value) => {
    newFilename.value = value;
  },
);

const { deleteQuotationFile, renameQuotationFile, downloadQuotationFile } =
  useCurrentInquiryStore();

async function onRemoveQuotationFile() {
  if (props.quotationFile.temporaryId) {
    throw new Error("Temporary file cannot be removed");
  }

  q.dialog({
    title: t("inquiryPage.quotationFiles.removeFile"),
    message: t("inquiryPage.quotationFiles.removeFileConfirmation", {
      filename: props.quotationFile.filename,
    }),
    ok: {
      label: t("inquiryPage.quotationFiles.removeFileOk"),
      color: "negative",
      dense: true,
    },
    cancel: {
      label: t("inquiryPage.quotationFiles.removeFileCancel"),
      dense: true,
      flat: true,
    },
  }).onOk(async () => {
    await deleteQuotationFile(props.quotationFile.id);
  });
}

async function onRenameQuotationFile() {
  if (newFilename.value.length > MAX_UPLOADED_FILE_NAME_LENGTH) {
    q.notify({
      message: t("errors.filenameTooLong", {
        maxLength: MAX_UPLOADED_FILE_NAME_LENGTH,
      }),
      color: "negative",
    });
    return;
  }
  isRenaming.value = true;
  try {
    await renameQuotationFile(props.quotationFile.id, newFilename.value);
    showRenamePopup.value = false;
  } finally {
    isRenaming.value = false;
  }
}

function closeRenamePopup() {
  showRenamePopup.value = false;
  newFilename.value = props.quotationFile.filename;
}

function selectFileStem(event: FocusEvent) {
  const input = event.target as HTMLInputElement;
  input.setSelectionRange(0, input.value.lastIndexOf("."));
}
</script>
