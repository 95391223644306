import * as pdfjs from "pdfjs-dist";

import type { PDFDocumentProxy, PDFPageProxy } from "pdfjs-dist";

function initPdfjsWorker() {
  try {
    if (typeof window === "undefined" || !("Worker" in window)) {
      throw new Error("Web Workers not supported in this environment.");
    }
    pdfjs.GlobalWorkerOptions.workerSrc = "/pdfjs/pdf.worker.min.mjs";
  } catch (error) {
    console.error(`Unable to load pdfjs: ${error}`);
  }
}

initPdfjsWorker();

export async function getPdfFromFile(file: File): Promise<PDFDocumentProxy> {
  const data = await file.arrayBuffer();
  const typedArray = new Uint8Array(data);
  return await pdfjs.getDocument({ data: typedArray }).promise;
}

export async function getPagesFromPdf(
  pdf: PDFDocumentProxy
): Promise<PDFPageProxy[]> {
  const pages = [];
  for (let i = 1; i <= pdf.numPages; i++) pages.push(await pdf.getPage(i));
  return pages;
}

export function getFirstPageViewport(pages: PDFPageProxy[]) {
  if (!pages.length) return { width: 0, height: 0 };
  const [page] = pages;
  return page.getViewport({ scale: 1.0 });
}
