import type { Manufacturer } from "@/types/manufacturer";
import { client } from "./client/client";

export async function findManufacturers(
  organizationId: number,
  search: string | null,
  limit: number = 30,
) {
  const response = await client.get<{ results: Manufacturer[] }>(
    `/organizations/${organizationId}/manufacturers/`,
    { params: { search, page_size: limit } },
  );
  return response.data.results.slice(0, limit);
}
