<template>
  <q-dialog ref="dialogRef" @hide="onDialogHide">
    <q-card class="dialog-card">
      <div
        class="row no-wrap items-center justify-between header-row q-px-md q-py-sm"
      >
        <div class="col-auto text-lg q-mr-md">
          {{ supplier.name }}
        </div>
        <q-btn
          flat
          dense
          icon="close"
          @click="onDialogHide"
          aria-label="Close"
        />
      </div>
      <div class="q-px-md q-pt-sm q-pb-lg column no-wrap gap-sm">
        <div class="text-bold">{{ $t("supplier.contacts") }}</div>
        <q-skeleton v-if="isLoading" class="contacts-skeleton" />
        <q-table
          v-else
          dense
          :rows="contacts"
          :columns="columns"
          class="contacts-table"
          :pagination="{ rowsPerPage: 10 }"
          ref="contactsTableEl"
        >
          <template #body-cell-actions="props">
            <q-td :props="props" class="row no-wrap items-center justify-end">
              <div>
                <q-btn
                  flat
                  dense
                  size="sm"
                  color="neutral-7"
                  icon="edit"
                  @click="handleEdit(props.row)"
                  :disable="props.row.importedAt !== null"
                />

                <q-tooltip v-if="props.row.importedAt" :delay="500">
                  {{ $t("supplierDialog.cannotEditImported") }}
                </q-tooltip>
              </div>
              <div>
                <q-btn
                  flat
                  dense
                  size="sm"
                  color="neutral-7"
                  icon="delete"
                  @click="handleDelete(props.row)"
                  :disable="props.row.importedAt !== null"
                />
                <q-tooltip v-if="props.row.importedAt" :delay="500">
                  {{ $t("supplierDialog.cannotDeleteImported") }}
                </q-tooltip>
              </div>
            </q-td>
          </template>
        </q-table>
        <div class="row no-wrap items-center justify-end">
          <q-btn
            flat
            dense
            @click="handleAdd"
            :disable="isLoading"
            :label="$t('supplierDialog.addContact')"
          />
        </div>
      </div>
    </q-card>
  </q-dialog>
</template>

<script setup lang="ts">
import * as api from "@/api/supplier";
import SupplierContactDialog from "@/components/SupplierContactDialog.vue";
import type { Supplier, SupplierContact } from "@/types/supplier";
import {
  QTable,
  useDialogPluginComponent,
  useQuasar,
  type QTableProps,
} from "quasar";
import { onMounted, ref } from "vue";
import { useI18n } from "vue-i18n";

const props = defineProps<{
  supplier: Supplier;
}>();

defineEmits([...useDialogPluginComponent.emits]);

const { dialogRef, onDialogHide } = useDialogPluginComponent();

const isLoading = ref(false);

const contacts = ref<SupplierContact[]>([]);
const contactsTableEl = ref<QTable | null>(null);

onMounted(async () => {
  isLoading.value = true;
  try {
    contacts.value = await api.listSupplierContacts(props.supplier.id);
  } finally {
    isLoading.value = false;
  }
});

const { t } = useI18n();
const columns: QTableProps["columns"] = [
  {
    name: "firstName",
    label: t("supplierContact.firstName"),
    field: "firstName",
    align: "left",
  },
  {
    name: "lastName",
    label: t("supplierContact.lastName"),
    field: "lastName",
    align: "left",
  },
  {
    name: "address",
    label: t("supplierContact.email"),
    field: "address",
    align: "left",
  },
  {
    name: "jobTitle",
    label: t("supplierContact.jobTitle"),
    field: "jobTitle",
    align: "left",
  },

  {
    name: "actions",
    label: t("supplierDialog.actions"),
    field: "id",
    align: "right",
  },
];

const q = useQuasar();

function handleDelete(contact: SupplierContact) {
  q.dialog({
    title: t("supplierDialog.deleteContact"),
    message: t("supplierDialog.deleteContactMessage", {
      name: getContactName(contact),
    }),
    ok: {
      dense: true,
      label: t("supplierDialog.deleteContact"),
      color: "negative",
    },
    cancel: {
      flat: true,
      dense: true,
    },
  }).onOk(async () => {
    await api.deleteSupplierContact(props.supplier.id, contact.id);
    contacts.value = contacts.value.filter((c) => c.id !== contact.id);
  });
}

function handleAdd() {
  q.dialog({
    component: SupplierContactDialog,
    componentProps: {
      supplierId: props.supplier.id,
      initialEmail: "",
    },
  }).onOk((contact: SupplierContact) => {
    contacts.value.unshift(contact);
    contactsTableEl.value?.firstPage();
  });
}

function handleEdit(contact: SupplierContact) {
  q.dialog({
    component: SupplierContactDialog,
    componentProps: {
      supplierId: props.supplier.id,
      contact,
      isEdit: true,
    },
  }).onOk((updatedContact: SupplierContact) => {
    contacts.value = contacts.value.map((c) =>
      c.id === updatedContact.id ? updatedContact : c
    );
  });
}

function getContactName(contact: SupplierContact) {
  if (contact.firstName && contact.lastName) {
    return `${contact.firstName} ${contact.lastName}`;
  }
  return contact.address;
}
</script>

<style scoped lang="scss">
.header-row {
  border-bottom: 1px solid $neutral-3;
  position: sticky;
  top: 0;
  z-index: 1;
  background-color: $white;
}

.dialog-card {
  min-width: 632px;
  width: unset;
  max-width: 95vw !important;
}

.contacts-table {
  min-width: 600px;
  max-width: 100% !important;
}

.contacts-skeleton {
  min-width: 600px;
  max-width: 100% !important;
  height: 200px;
}
</style>
