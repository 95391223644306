import { client, clientWithoutErrorNotifications } from "@/api/client/client";
import type { QuotationFile } from "@/types/quotation";

export async function downloadQuotationFile(
  inquiryId: number,
  quotationFileId: number
): Promise<void> {
  window.open(
    `/api/inquiries/${inquiryId}/quotation_files/${quotationFileId}/download/`,
    "_blank"
  );
}

export async function deleteQuotationFile(
  inquiryId: number,
  quotationFileId: number
): Promise<void> {
  await client.delete(
    `/inquiries/${inquiryId}/quotation_files/${quotationFileId}/`
  );
}

export async function createQuotationFile(
  inquiryId: number,
  file: File
): Promise<QuotationFile> {
  const formData = new FormData();
  formData.append("file", file);
  const url = `/inquiries/${inquiryId}/quotation_files/`;

  const response = await clientWithoutErrorNotifications.post(url, formData);
  return response.data;
}

export async function renameQuotationFile(
  inquiryId: number,
  quotationFileId: number,
  filename: string
): Promise<void> {
  await client.patch(`/inquiries/${inquiryId}/quotation_files/${quotationFileId}/`, {
    filename,
  });
}
