<template>
  <q-dialog ref="dialogRef" @hide="onDialogHide" persistent>
    <q-card class="manufacturer-select-dialog-card column no-wrap q-pa-sm">
      <div class="q-pa-sm">
        <div class="text-h6">
          {{ $t("manufacturerSelectDialog.header") }}
        </div>
        <div class="text-body2 text-neutral-8 q-mt-sm">
          {{ $t("manufacturerSelectDialog.description") }}
        </div>
      </div>
      <q-separator />
      <async-multi-select
        :disable="isLoading"
        :selected-options="selectedManufacturers"
        :partially-selected-options="[]"
        :preload-options="[]"
        :search-placeholder="$t('manufacturerSelectButton.searchPlaceholder')"
        :find-options="findManufacturers"
        :label-fn="(manufacturer) => manufacturer.name"
        :filter-fn="filterManufacturers"
        id-value="id"
        :max-options-displayed="MAX_OPTIONS_DISPLAYED"
        @select="selectManufacturer"
        @unselect="unselectManufacturer"
      />
      <q-space />
      <q-card-actions align="right">
        <q-btn dense flat @click="onDialogCancel" :disable="isLoading">{{
          $t("manufacturerSelectDialog.cancel")
        }}</q-btn>
        <q-btn
          dense
          color="primary"
          @click="updatePreferredManufacturers"
          :loading="isLoading"
        >
          {{ $t("manufacturerSelectDialog.ok") }}
        </q-btn>
      </q-card-actions>
    </q-card>
  </q-dialog>
</template>

<script setup lang="ts">
import * as api from "@/api/manufacturer";
import AsyncMultiSelect from "@/components/SelectMenus/AsyncMultiSelect.vue";
import { useRouteParams } from "@/composables/useRouteParams";
import { useCurrentInquiryStore } from "@/stores/currentInquiry";
import { useCurrentOfferPositionGroupsStore } from "@/stores/currentOfferPositionsGroups";
import type { Manufacturer } from "@/types/manufacturer";
import { useDialogPluginComponent } from "quasar";
import { ref } from "vue";

const props = defineProps<{
  preferredManufacturers: Manufacturer[];
}>();

defineEmits([...useDialogPluginComponent.emits]);

const { dialogRef, onDialogHide, onDialogOK, onDialogCancel } =
  useDialogPluginComponent();

const opgStore = useCurrentOfferPositionGroupsStore();

const selectedManufacturers = ref<Manufacturer[]>([
  ...props.preferredManufacturers,
]);

const { organizationId } = useRouteParams();

const MAX_OPTIONS_DISPLAYED = 10;

async function findManufacturers(searchText: string) {
  const manufacturers = await api.findManufacturers(
    organizationId.value,
    searchText,
    MAX_OPTIONS_DISPLAYED,
  );
  return manufacturers;
}

function filterManufacturers(manufacturer: Manufacturer, searchText: string) {
  return (
    manufacturer.name.toLowerCase().includes(searchText.toLowerCase()) ||
    manufacturer.externalId?.toLowerCase().includes(searchText.toLowerCase())
  );
}

function selectManufacturer(manufacturer: Manufacturer) {
  selectedManufacturers.value.push(manufacturer);
}

function unselectManufacturer(manufacturer: Manufacturer) {
  selectedManufacturers.value = selectedManufacturers.value.filter(
    (m) => m.id !== manufacturer.id,
  );
}

const store = useCurrentInquiryStore();
const isLoading = ref(false);

async function updatePreferredManufacturers() {
  if (manufacturersAreUnchanged()) {
    onDialogOK();
    return;
  }

  isLoading.value = true;
  try {
    await store.updatePreferredManufacturers(selectedManufacturers.value);
    opgStore.clear();
    onDialogOK();
  } finally {
    isLoading.value = false;
  }
}

function manufacturersAreUnchanged() {
  return (
    selectedManufacturers.value.length ===
      props.preferredManufacturers.length &&
    selectedManufacturers.value.every((m) =>
      props.preferredManufacturers.includes(m),
    )
  );
}
</script>

<style>
.manufacturer-select-dialog-card {
  height: 560px;
  max-height: 90vh;
  width: 500px;
  max-width: 90vw;
}
</style>
