<template>
  <q-item dense clickable @click="showNewInboxDialog">
    <q-item-section class="q-ml-lg force-row">
      <q-icon name="sym_r_add" class="q-mr-xs" />
      <q-item-label>{{ $t("settings.newInboxDialog.label") }}</q-item-label>
    </q-item-section>
  </q-item>
</template>

<script setup lang="ts">
import NewInboxDialog from "@/components/Settings/NewInboxDialog.vue";
import { useQuasar } from "quasar";

const q = useQuasar();

function showNewInboxDialog() {
  q.dialog({
    component: NewInboxDialog,
  });
}
</script>

<style lang="scss">
.force-row {
  display: flex !important;
  flex-direction: row !important;
  align-items: center !important;
  justify-content: flex-start !important;
}
</style>
