import type { ProductGroup } from "@/types/productGroup";

export type Unit =
  | "LUMPSUM"
  | "PERHOUR"
  | "PERTOUR"
  | "MINUTE"
  | "HOUR"
  | "DAY"
  | "WEEK"
  | "MONTH"
  | "YEAR"
  | "GRAM"
  | "KILOGRAMM"
  | "TON"
  | "MILLIMETER"
  | "CENTIMETER"
  | "CENTIMETER2"
  | "METER"
  | "METER2"
  | "METER3"
  | "KILOMETER"
  | "LITER"
  | "PIECE"
  | "PALLET"
  | "SLAB"
  | "PACK"
  | "CARTON"
  | "ROLL"
  | "BAG"
  | "BOARD"
  | "BUNDLE"
  | "BUCKET"
  | "SACK"
  | "SET"
  | "PAIR"
  | "SHEET"
  | "CAN"
  | "PACKAGE"
  | "BOTTLE"
  | "BLOCK"
  | "CANISTER"
  | "PINT_US"
  | "ROD"
  | "BIGBAG"
  | "BOX"
  | "KILOWATT"
  | "KILOWATT_PEAK"
  | "CRATE"
  | "METER_WEEK"
  | "METER2_WEEK"
  | "PIECE_WEEK"
  | "RNG"
  | "E_3_LITER"
  | "E_1_LITER"
  | "MPALLET"
  | "LPALLET"
  | "BLISTER"
  | "CONTAINER"
  | "E2_GRAM"
  | "E3_GRAM"
  | "E5_GRAM"
  | "BARREL"
  | "HOBBOCK"
  | "TUBE"
  | "E_1_METER3"
  | "ZENTNER"
  | "DRUM"
  | "LAYER"
  | "FITTINGS"
  | "DISPLAY"
  | "LOAD"
  | "CARTRIDGE"
  | "LARGE_BUNDLE"
  | "STACK"
  | "TRUCKLOAD";

export const LUMPSUM_UNITS = ["LUMPSUM", "PERHOUR", "PERTOUR"];
export interface Product {
  id: number;
  name: string;
  searchKeywords: string | null;
  description: string;
  externalId: string;

  unit: Unit;
  alternativeUnits: Unit[];

  isActive: boolean;
  conversions: ConversionsMap;

  hasVariant: boolean;
  isVariantConfigurable: boolean;

  listPrice: number | null;
  purchasePrice: number | null;
  vatTaxRate: number | null;
  isNameEditable: boolean;

  isPriceAdjustable: boolean;
  useMarkupCalculation: boolean;
  group: ProductGroup | null;
}

export interface ApiProduct
  extends Omit<Product, "listPrice" | "vatTaxRate" | "purchasePrice"> {
  listPrice: string | null;
  vatTaxRate: string | null;
  purchasePrice: string | null;
}

export interface ConversionsMap {
  [key: string]: Conversion;
}

export interface Conversion {
  numerator: number;
  denominator: number;
}

export interface ProductCandidate {
  id: number;
  product: Product;
  confidence: number;
}

export interface ApiProductCandidate extends Omit<ProductCandidate, "product"> {
  product: ApiProduct;
}
