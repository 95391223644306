<template>
  <gaeb-table-row
    ref="gaebTableRowEl"
    :is-html="isHtml"
    :reference-id="element.referenceId"
    :text-short="element.textShort"
    :amount="`${element.amount === null ? '' : element.amount} ${element.originalUnit}`"
    @click="handleClick"
    :class="classes"
    :is-selected="isSelected"
  >
    <template #topline>
      <div class="text-italic">
        <div v-if="element.provis">
          {{ $t(`gaebViewer.positionDetails.provis.${element.provis}`) }}
        </div>
        <div v-if="element.lumpSumItem">
          {{ $t("gaebViewer.positionDetails.lumpSumItem") }}
        </div>
        <div v-if="element.hourItem">
          {{ $t("gaebViewer.positionDetails.hourItem") }}
        </div>
        <div v-if="element.keyItem">
          {{ $t("gaebViewer.positionDetails.keyItem") }}
        </div>
        <div v-if="element.markupItem">
          {{ $t("gaebViewer.positionDetails.markupItem") }}
        </div>
        <div v-if="element.quantityTbd">
          {{ $t("gaebViewer.positionDetails.quantityTbd") }}
        </div>
        <div v-if="element.performanceDescriptionNumber">
          {{
            $t("gaebViewer.positionDetails.performanceDescriptionReference", {
              id: element.performanceDescriptionNumber
            })
          }}
        </div>
        <div
          v-if="
            element.alternativeSeriesNumber || element.alternativeGroupNumber
          "
        >
          {{
            $t("gaebViewer.positionDetails.alternativePositionReference", {
              groupNumber: element.alternativeGroupNumber,
              seriesNumber: element.alternativeSeriesNumber
            })
          }}
        </div>
        <div v-else-if="element.isAlternative">
          {{
            $t("gaebViewer.positionDetails.alternativePosition", {
              groupNumber: element.alternativeGroupNumber,
              seriesNumber: element.alternativeSeriesNumber
            })
          }}
        </div>
        <div v-if="element.addendumNumber">
          {{
            $t("gaebViewer.positionDetails.addendumReference", {
              addendumNumber: element.addendumNumber
            })
          }}
        </div>
        <div v-if="element.referencedText == 'Ref'">
          {{ $t("gaebViewer.positionDetails.isReferenceDescription") }}
        </div>
        <div v-if="element.referencedText == 'Rep'">
          {{ $t("gaebViewer.positionDetails.isRepetitionDescription") }}
        </div>
      </div>
    </template>
    <template #text>
      <div v-html="positionText" :class="{ 'gaeb-pre-wrap': !isHtml }" />
      <div
        v-for="(subDescription, idx) in element.subDescriptions"
        :key="idx"
        class="q-mt-md"
      >
        <div class="text-bold q-mb-sm">
          <span v-if="subDescription!.number !== null">
            {{ subDescription!.number }}.
          </span>
          {{ subDescription!.textShort }}
        </div>
        <div
          v-html="subDescription!.textHtml"
          :class="{ 'gaeb-pre-wrap': !isHtml }"
        />
      </div>
    </template>
  </gaeb-table-row>
</template>

<script setup lang="ts">
import { useGaebScrollToHighlight } from "@/composables/gaebViewer/useGaebScrollToHighlight";
import { usePositionsEvents } from "@/composables/usePositionsEvents";
import { useSelectedPositionIds } from "@/composables/useSelectedPositionIds";
import type { GaebPosition } from "@/types/gaeb";
import { computed, inject, onMounted, ref } from "vue";
import GaebTableRow from "./GaebTableRow.vue";

const props = defineProps<{
  element: GaebPosition;
  isHtml: boolean;
}>();

const gaebTableRowEl = ref<typeof GaebTableRow | null>(null);

const positionText = computed(() => {
  if (props.isHtml) return props.element.textHtml.replace(/>\s+</g, "><");
  return props.element.textHtml;
});

const registerScroller = useGaebScrollToHighlight();

onMounted(() => {
  registerScroller(props.element.id, () => {
    gaebTableRowEl.value?.scrollIntoView();
  });
});

const useSelection = inject<boolean>("gaeb-viewer-use-selection");

const selectedPositionIds = useSelection && useSelectedPositionIds();

const events = useSelection && usePositionsEvents();

function handleClick() {
  if (!events || !selectedPositionIds) return;

  if (selectedPositionIds.value.length == 0) {
    // No position selected, so we select this one
    events.emit("selectFromDocument", props.element.id);
    return;
  }
  // This position is selected, we do nothing
  if (selectedPositionIds.value.includes(props.element.id)) return;
  // There is another selected position, we clear the selection
  events.emit("clearSelection");
}

const classes = computed(() => ({
  "text-neutral-5":
    selectedPositionIds &&
    selectedPositionIds.value.length > 0 &&
    !selectedPositionIds.value.includes(props.element.id),
  "cursor-pointer":
    selectedPositionIds &&
    !selectedPositionIds.value.includes(props.element.id)
}));

const isSelected = computed(() => {
  return (
    selectedPositionIds && selectedPositionIds.value.includes(props.element.id)
  );
});
</script>
