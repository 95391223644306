<template>
  <q-dialog ref="dialogRef" @hide="onDialogHide">
    <q-card class="q-dialog-plugin">
      <q-card-section class="row justify-between items-center">
        <div class="text-xl">
          {{
            isEdit
              ? $t("supplierContactDialog.titleEdit")
              : $t("supplierContactDialog.titleAdd")
          }}
        </div>
        <q-btn flat round dense icon="sym_r_close" @click="onDialogHide" />
      </q-card-section>
      <q-card-section>
        <q-form @submit="addSupplierContact" class="column no-wrap gap-sm">
          <q-input
            autofocus
            dense
            outlined
            v-model="firstName"
            :label="$t('supplierContact.firstName')"
          />
          <q-input
            dense
            outlined
            v-model="lastName"
            :label="$t('supplierContact.lastName')"
          />
          <q-input
            dense
            outlined
            v-model="email"
            :label="$t('supplierContact.email')"
            :rules="['email']"
            @keyup.enter="handleSubmit"
          />
          <q-input
            dense
            outlined
            v-model="jobTitle"
            :label="$t('supplierContact.jobTitle')"
          />
          <q-input
            dense
            outlined
            v-model="notes"
            :label="$t('supplierContact.notes')"
            type="textarea"
          />

          <q-banner rounded class="bg-negative text-white q-mb-md" v-if="error">
            {{ $t(`supplierContactDialog.errors.${error}`) }}
            <template v-slot:action>
              <q-btn
                flat
                color="white"
                :label="$t('Dismiss')"
                @click="error = null"
              />
            </template>
          </q-banner>

          <div class="row justify-end full-width">
            <q-btn
              dense
              flat
              @click="handleSubmit"
              :disable="!email.length || isLoading"
            >
              {{
                isEdit
                  ? $t("supplierContactDialog.okButtonEdit")
                  : $t("supplierContactDialog.okButtonAdd")
              }}
            </q-btn>
          </div>
        </q-form>
      </q-card-section>
      <q-inner-loading :showing="isLoading" />
    </q-card>
  </q-dialog>
</template>

<script setup lang="ts">
import { createSupplierContact, updateSupplierContact } from "@/api/supplier";
import { useCurrentSupplierStore } from "@/stores/currentSupplier";
import type { SupplierContact } from "@/types/supplier";
import { isAxiosError } from "axios";
import { storeToRefs } from "pinia";
import { useDialogPluginComponent } from "quasar";
import { ref } from "vue";

defineEmits([...useDialogPluginComponent.emits]);

const { dialogRef, onDialogHide, onDialogOK } = useDialogPluginComponent();

const props = withDefaults(
  defineProps<{
    initialEmail?: string;
    supplierId: number;
    contact?: SupplierContact;
    isEdit?: boolean;
  }>(),
  {
    isEdit: false,
  },
);

const currentSupplierStore = useCurrentSupplierStore();
const { supplier: currentSupplier } = storeToRefs(currentSupplierStore);

const isLoading = ref(false);
const error = ref<string | null>(null);

const firstName = ref(props.contact?.firstName ?? "");
const lastName = ref(props.contact?.lastName ?? "");
const email = ref(props.contact?.address ?? props.initialEmail ?? "");
const jobTitle = ref(props.contact?.jobTitle ?? "");
const notes = ref(props.contact?.notes ?? "");

async function handleSubmit() {
  if (props.isEdit) {
    await editSupplierContact();
  } else {
    await addSupplierContact();
  }
}

async function addSupplierContact() {
  if (!email.value) return;

  isLoading.value = true;
  error.value = null;

  const data = {
    firstName: firstName.value,
    lastName: lastName.value,
    address: email.value,
    jobTitle: jobTitle.value,
    notes: notes.value,
  };

  try {
    let contact: SupplierContact;

    if (currentSupplier.value?.id === props.supplierId) {
      // If the supplier is the current supplier, we can add the contact to the store
      contact = await currentSupplierStore.addContact(data);
    } else {
      // If the supplier is not the current supplier, we need to add the contact via API
      contact = await createSupplierContact(props.supplierId, data, {
        ignoreErrors: [400],
      });
    }

    if (contact) {
      onDialogOK(contact);
    }
  } catch (e) {
    if (isAxiosError(e) && e.response?.data?.reasons) {
      error.value = e.response?.data?.reasons?.[0];
    } else {
      throw e;
    }
  } finally {
    isLoading.value = false;
  }
}

async function editSupplierContact() {
  if (!email.value) return;
  if (!props.contact?.id) throw new Error("Contact ID is required");

  isLoading.value = true;
  error.value = null;

  const data = {
    firstName: firstName.value,
    lastName: lastName.value,
    address: email.value,
    jobTitle: jobTitle.value,
    notes: notes.value,
  };

  try {
    let contact: SupplierContact;
    if (currentSupplier.value?.id === props.supplierId) {
      // If the supplier is the current supplier, we can update the contact in the store
      contact = await currentSupplierStore.updateContact(
        props.contact.id,
        data,
      );
    } else {
      // If the supplier is not the current supplier, we need to update the contact via API
      contact = await updateSupplierContact(
        props.supplierId,
        props.contact.id,
        data,
        { ignoreErrors: [400] },
      );
    }
    onDialogOK(contact);
  } catch (e) {
    if (isAxiosError(e) && e.response?.data?.reasons) {
      error.value = e.response?.data?.reasons?.[0];
    } else {
      throw e;
    }
  } finally {
    isLoading.value = false;
  }
}
</script>
