<template>
  <div class="row no-wrap full-width items-center">
    <DonutChart :data="donutElements" :size="100" :border-size="16" />
  </div>
</template>

<script setup lang="ts">
import type { InquiryProgress } from "@/types/inquiry";
import { computed } from "vue";
import DonutChart from "./DonutChart.vue";
import { useI18n } from "vue-i18n";

const props = defineProps<{
  progress: InquiryProgress | null;
}>();

const { t } = useI18n();

const donutElements = computed(() => {
  if (!props.progress) return [];
  const { completed, offered, total } = props.progress;
  const notCompleted = offered - completed;
  const notOffered = total - offered;
  return [
    {
      value: completed,
      color: "var(--q-primary-7)",
      label: t("inquiryPage.progress.finalized"),
    },
    {
      value: notCompleted,
      color: "var(--q-primary-3)",
      label: t("inquiryPage.progress.notFinalized"),
    },
    {
      value: notOffered,
      color: "var(--q-neutral-2)",
      label: t("inquiryPage.progress.notOffered"),
    },
  ];
});
</script>
