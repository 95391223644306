import { useCurrentInboxUsersStore } from "@/stores/currentInboxUsers";
import type {
  SuggestionKeyDownProps,
  SuggestionProps,
} from "@tiptap/suggestion";
import { VueRenderer } from "@tiptap/vue-3";
import { storeToRefs } from "pinia";
import tippy, { type Instance } from "tippy.js";
import UserList from "./UserList.vue";

export default {
  items: ({ query }: { query: string }) => {
    const { users } = storeToRefs(useCurrentInboxUsersStore());

    return users.value
      .filter(
        (user) =>
          user.email.toLowerCase().includes(query.toLowerCase()) ||
          user.firstName.toLowerCase().includes(query.toLowerCase()) ||
          user.lastName.toLowerCase().includes(query.toLowerCase())
      )
      .slice(0, 5);
  },

  render: () => {
    let component: VueRenderer | undefined;
    let popup: Instance | undefined;

    return {
      onStart: (props: SuggestionProps) => {
        component = new VueRenderer(UserList, {
          props,
          editor: props.editor,
        });

        if (!props.clientRect) {
          return;
        }

        popup = tippy(document.body, {
          getReferenceClientRect: getClientRectFromProps(props),
          appendTo: () => document.body,
          content: component.element ?? undefined,
          showOnCreate: true,
          interactive: true,
          trigger: "manual",
          placement: "bottom-start",
        });
      },

      onUpdate: (props: SuggestionProps) => {
        if (component) {
          component.updateProps(props);
        }

        if (!props.clientRect || !popup) {
          return;
        }

        popup.setProps({
          getReferenceClientRect: getClientRectFromProps(props),
        });
      },

      onKeyDown: (props: SuggestionKeyDownProps) => {
        if (props.event.key === "Escape" && popup) {
          popup.hide();
          return true;
        }

        return component?.ref?.onKeyDown(props) || false;
      },

      onExit: () => {
        if (popup) {
          popup.destroy();
        }
        if (component) {
          component.destroy();
        }
      },
    };
  },
};

const getClientRectFromProps = (props: SuggestionProps) => () => {
  if (!props.clientRect) throw new Error("clientRect is not available");
  const clientRect = props.clientRect();
  if (!clientRect) throw new Error("clientRect is not available");
  return clientRect;
};
