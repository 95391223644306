<template>
  <q-menu
    :offset="[0, 4]"
    ref="menuRef"
    @keydown.escape.stop="menuRef?.hide()"
    @keydown.stop
  >
    <q-card
      @keydown.stop.prevent.down="selectNext"
      @keydown.stop.prevent.up="selectPrevious"
      @keydown.stop.prevent.enter="
        selectedIndex !== null && select(filteredOptions[selectedIndex])
      "
    >
      <div class="q-px-sm">
        <q-input
          borderless
          dense
          :placeholder="props.searchPlaceholder"
          v-model="searchText"
          autofocus
        />
      </div>
      <q-separator />
      <div>
        <q-list dense class="q-py-xs">
          <template v-if="props.isLoading">
            <q-item>
              <q-item-section class="row no-wrap justify-center items-center">
                <q-circular-progress
                  indeterminate
                  size="20px"
                  color="primary"
                />
              </q-item-section>
            </q-item>
          </template>
          <template v-else>
            <template
              v-for="(option, index) in filteredOptions.slice(
                0,
                props.maxOptionsDisplayed,
              )"
              :key="option[idValue]"
            >
              <slot
                name="option"
                :option="option"
                :select="select"
                :index="index"
              >
                <q-item
                  dense
                  clickable
                  v-ripple
                  v-close-popup
                  @click="select(option)"
                  class="q-mx-xs rounded-borders force-small-x-padding"
                  :class="{
                    'bg-neutral-3': isActiveOption(option),
                    'bg-neutral-2': selectedIndex === index,
                  }"
                >
                  <q-item-section>
                    <slot name="option-label" :option="option">
                      {{ props.labelFn(option) }}
                    </slot>
                  </q-item-section>
                </q-item>
              </slot>
            </template>
          </template>
        </q-list>
      </div>
    </q-card>
  </q-menu>
</template>

<script
  setup
  lang="ts"
  generic="
    IdValue extends string,
    SearchKey extends string,
    Option extends { [key in SearchKey]: string } & {
      [key in IdValue]: string | number;
    }
  "
>
import { computed, ref } from "vue";
import { useKeyboardSelection } from "./useKeyboardSelection";
import type { QMenu } from "quasar";

const props = withDefaults(
  defineProps<{
    options: Option[];
    selectedOption: Option | null;
    searchPlaceholder: string;
    idValue: IdValue;
    searchValues: SearchKey[];
    labelFn: (option: Option) => string;
    maxOptionsDisplayed?: number;
    isLoading?: boolean;
  }>(),
  { maxOptionsDisplayed: 10, isLoading: false },
);

const emit = defineEmits<{
  select: [option: Option];
}>();

const searchText = ref<string | null>(null);
const menuRef = ref<typeof QMenu | null>(null);

const filteredOptions = computed(() => {
  if (!searchText.value) {
    return props.options;
  }
  return props.options.filter((option) =>
    props.searchValues.some((value) =>
      option[value].toLowerCase().includes(searchText.value!.toLowerCase()),
    ),
  );
});

const { selectedIndex, selectNext, selectPrevious } =
  useKeyboardSelection(filteredOptions);

function isActiveOption(option: Option) {
  return (
    props.selectedOption &&
    option[props.idValue] === props.selectedOption[props.idValue]
  );
}

function select(option: Option) {
  emit("select", option);
  searchText.value = null;
}
</script>

<style scoped>
.force-small-x-padding {
  padding-left: 8px;
  padding-right: 8px;
}
</style>
