import { MAX_NUM_PRODUCTS_SHOWN_IN_DROPDOWN } from "@/config/constants";
import type { Paginated } from "@/types/pagination";
import type { ApiProduct, Product } from "@/types/product";
import { client } from "./client/client";
import { parseCursor } from "./utils";

export async function findProducts(
  organizationId: number,
  search: string | null,
  limit: number = MAX_NUM_PRODUCTS_SHOWN_IN_DROPDOWN
): Promise<Product[]> {
  const response = await client.get<ApiProduct[]>(
    `/organizations/${organizationId}/products/search/`,
    { params: { search, page_size: limit } }
  );
  return productsFromApi(response.data.slice(0, limit));
}

export async function listProducts(
  organizationId: number,
  search: string | null,
  cursor: string | null,
  pageSize: number
): Promise<Paginated<Product>> {
  const response = await client.get<Paginated<ApiProduct>>(
    `/organizations/${organizationId}/products/`,
    { params: { search, cursor, page_size: pageSize, page_result_count: true } }
  );
  return {
    ...response.data,
    results: productsFromApi(response.data.results),
    next: parseCursor(response.data.next),
    previous: parseCursor(response.data.previous),
  };
}

export function productFromApi(product: ApiProduct): Product {
  return {
    ...product,
    listPrice:
      product.listPrice === null ? null : parseFloat(product.listPrice),
    vatTaxRate:
      product.vatTaxRate === null ? null : parseFloat(product.vatTaxRate),
    purchasePrice:
      product.purchasePrice === null ? null : parseFloat(product.purchasePrice),
  };
}

export function productsFromApi(products: ApiProduct[]): Product[] {
  return products.map(productFromApi);
}
