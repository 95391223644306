import { setupApp } from "@/setup/index";
import { createApp } from "vue";
import App from "./App.vue";

import "@/css/_global-classes.scss";

const app = createApp(App);

setupApp(app);

app.mount("#app");
