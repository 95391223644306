import { cmdOrCtrlSymbol } from "@/utils/cmdOrCtrl";

export default {
  loggedInLayout: {
    organizationNotFound: "Diese Organisation scheint nicht zu existieren :-(",
    backToRoot: "Zurück zur Startseite",
  },
  deleteSingleInquiryMenuEntry: {
    label: "Anfrage löschen",
    confirmDelete: {
      title: "Anfrage löschen",
      message:
        "Sind Sie sicher, dass Sie Anfrage {inquiryId} unwiderbringlich löschen möchten?",
    },
  },
  deleteMultiInquiryButton: {
    confirmDelete: {
      title: "Anfragen löschen",
      message:
        "Sind Sie sicher, dass Sie die ausgewählten {numInquiries} Anfragen löschen möchten?",
    },
  },
  moveMultiInquiryButton: {
    label: "In anderen Ordner verschieben",
    confirmMove: {
      title: "Anfragen verschieben",
      message:
        "Sind Sie sicher, dass Sie die ausgewählten {numInquiries} Anfragen in den Ordner {targetInbox} verschieben möchten?",
      ok: "Verschieben",
    },
  },
  moveInquiryButton: {
    label: "Anfrage verschieben",
    confirmMove: {
      title: "Anfrage verschieben",
      message:
        "Sind Sie sicher, dass Sie die Anfrage {inquiryId} in den Ordner '{targetInboxName}' verschieben möchten?",
      ok: "Anfrage verschieben",
    },
    searchPlaceholder: "Ordner finden",
  },
  downloadInquiryButton: {
    tooltip: "Angebotsdaten herunterladen",
    excelOfferFormat: "Angebots-Layout",
    excelBOQFormat: "LV-Layout",
    finalQuotationPdfFormat: "PDF herunterladen",
    gaeb: "GAEB herunterladen",
    excel: "Excel herunterladen",
    formats: {
      gaebP94: "p94",
      gaebX94: "x94",
      msoft_csv: "M-SOFT CSV",
      infor_xml: "Infor XML",
    },
  },
  inquiriesTable: {
    inquiryProgressDisplay: {
      tooltip: {
        total: "{total} Positionen im LV",
        offered: "{offered} Positionen angeboten",
        completed: "{completed} Positionen abgeschlossen",
      },
    },
  },
  exportInquiryButton: "Anfrage exportieren",
  copyInquiryButton: {
    label: "Anfrage kopieren",
    copyingInquiry: "Anfrage wird kopiert ...",
  },
  exportInquiryDialog: {
    ok: "Anfrage exportieren",
    cancel: "Abbrechen",
    errors: {
      EXPORT_SFTP_FAILED_NETWORK: {
        title:
          "Netzwerkverbindung zu Server {hostname} auf Port {port} fehlgeschlagen",
        suggestion:
          "Bitte stellen Sie sicher, dass Server Hostname und Port korrekt sind, der Server vom Internet erreichbar ist und evtl. vorhandene Firewalls richtig konfiguriert sind.",
      },
      EXPORT_SFTP_UNKNOWN_HOSTNAME: {
        title:
          "Netzwerkverbindung zu Server {hostname} fehlgeschlagen (Hostname unbekannt)",
        suggestion:
          "Bitte stellen Sie sicher, dass der Server Hostname stimmt und DNS korrekt konfiguriert ist.",
      },
      EXPORT_SFTP_FAILED_AUTH: {
        title: "Authentifizierung fehlgeschlagen (Benutzername/Passwort)",
        suggestion:
          "Bitte stellen Sie sicher, dass SFTP Benutzername und Passwort korrekt sind.",
      },
      EXPORT_SFTP_FAILED_PRIVATE_KEY: {
        title: "Authentifizierung fehlgeschlagen (Privater Schlüssel)",
        suggestion:
          "Bitte stellen Sie sicher, dass der SFTP Private Schlüssel korrekt ist.",
      },
      EXPORT_SFTP_FOLDER_NOT_FOUND: {
        title: "Ordner {directory} nicht gefunden",
        suggestion:
          "Bitte stellen Sie sicher, dass der Ordner auf dem SFTP Server existiert und der SFTP-Benutzer Zugriffsrechte hat.",
      },
      EXPORT_SFTP_WRITE_FAILED: {
        title: "Order {directory} nicht schreibbar",
        suggestion:
          "Bitte stellen Sie sicher, dass der SFTP-Benutzer Schreibrechte hat.",
      },
      EXPORT_ERROR: {
        title: "Export fehlgeschlagen",
        suggestion: "Bitte kontaktieren sie den kinisto Support über {email}",
        emailAddress: "support{'@'}kinisto.com",
      },
      EXPORT_REST_FAILED_NETWORK: {
        title: "Netzwerkverbindung zu Server {url} fehlgeschlagen",
        suggestion:
          "Bitte stellen Sie sicher, dass die Server-URL korrekt ist, der Server vom Internet erreichbar ist und evtl. vorhandene Firewalls richtig konfiguriert sind.",
      },
      EXPORT_REST_FAILED_AUTH: {
        title: "Authentifizierung fehlgeschlagen",
        suggestion:
          "Bitte stellen Sie sicher, dass die HTTP Header für API-Key Authentifizierung richtig konfiguriert sind.",
      },
      EXPORT_REST_404: {
        title: "URL {url} nicht gefunden",
        suggestion:
          "Bitte stellen Sie sicher, dass die URL korrekt ist und der Server vom Internet erreichbar ist.",
      },
    },
    inquiryWasAlreadyExported: {
      title: "Anfrage bereits exportiert",
      confirmation:
        "Diese Anfrage wurde bereits exportiert. Soll sie erneut exportiert werden?",
    },
    inquiryIsNotFinalized: {
      title: "Anfrage abschließen und exportieren",
      confirmation:
        "Die Anfrage wird festgeschrieben und in das ERP System exportiert. Angebotsdaten und Positionen können anschließend in kinisto nicht mehr bearbeitet werden.",
    },
    inquiryWasFinalized: {
      title: "Anfrage exportieren",
      confirmation:
        "Die Anfrage wird exportiert in das ERP System. Angebotsdaten und Positionen können anschließend in kinisto nicht mehr bearbeitet werden.",
    },
  },
  finalizeInquiryButton: "Anfrage abschließen",
  finalizeInquiryDialog: {
    title: "Anfrage abschließen",
    confirmation:
      "Die Anfrage wird festgeschrieben. Angebotsdaten und Positionen können anschließend nicht mehr bearbeitet werden.",
    ok: "Anfrage abschließen",
    cancel: "Abbrechen",
    inquiryNotCompleteDialog: {
      title: "⚠️ Nicht alle Positionen bearbeitet!",
      message:
        "Nicht alle Positionen wurden bearbeitet. Möchten Sie die Anfrage trotzdem abschließen?",
      ok: "Anfrage trotzdem abschließen",
      cancel: "Abbrechen",
    },
    errorMessages: {
      STATUS_NOT_REVIEWING:
        "Anfrage kann nur im Status 'In Bearbeitung' abgeschlossen werden.",
      HAS_POSITION_WITH_NULL_AMOUNT:
        "Positionen mit ungültigen Mengen vorhanden. Bitte überprüfen Sie die Positionen.",
      HAS_MISSING_CUSTOM_FIELDS:
        "Werte für '{customFieldNames}' sind erforderlich.",
      BUILDING_PROJECT_ID_REQUIRED: "Bauvorhaben ist erforderlich",
      CUSTOMER_ID_REQUIRED: "Kunde ist erforderlich",
      EXPORT_IS_NOT_CONFIGURED: "Der Export ist nicht korrekt konfiguriert.",
      EXPORT_FAILED: "Der Export ist fehlgeschlagen.",
    },
  },
  selectBOQsDialog: {
    title: "LV-Dateien auswählen",
    warningTitle: "LV-Dateien auswählen",
    warningMessage:
      "Bereits bearbeitete Positionen aus abgewählten LVs werden entfernt. Falls neue LVs ausgewählt wurden, wird die Anfrage auf Status 'Analyse' gesetzt.",
    ok: "LV-Dateien auswählen",
    cancel: "Abbrechen",
    nonPDF: "Bitte wählen Sie eine PDF-Datei aus.",
    buttonLabel: "LV-Dateien auswählen",
    disabledTooltip: "Nur für Anfragen in Bearbeitung möglich.",
  },
  statusSelectButton: {
    retryAnalysis: "Analyse erneut starten",
  },
  submissionDisplay: {
    today: "heute",
    tomorrow: "morgen",
    yesterday: "gestern",
  },
  masterDataSearchBar: {
    enterAtLeastNCharacters: "Mindestens {n} Zeichen eingeben...",
  },
  productSearchMenu: {
    noResults: "Keine passenden Produkte gefunden",
    enterAtLeastNCharacters: "Mindestens {n} Zeichen eingeben...",
  },
  productSearchBar: {
    enterAtLeastNCharacters: "Mindestens {n} Zeichen eingeben...",
  },
  customerSearchMenu: {
    noResults: "Keine passenden Kunden gefunden",
  },
  dateInput: {
    notValidDate: "Kein gültiges Datum",
  },
  numberInput: {
    valueMissing: "Wert fehlt",
  },
  inquiryPage: {
    title: "Anfrage",
    notFound: "Diese Anfrage scheint nicht zu existieren :-(",
    inquiryDataHeader: "Anfrage",
    quotationDataHeader: "Angebot",
    positions: {
      header: "Positionen",
    },
    progress: {
      header: "Fortschritt",
      description: "relevante Positionen bearbeitet",
      finalized: "fertig",
      notFinalized: "nicht fertig",
      notOffered: "nicht angeboten",
      edit: "Positionen bearbeiten",
    },
    toSupplierRfqs: "Lieferantenanfragen einsehen",
    discounts: {
      header: "Rabatte",
    },
    inquiryBaseData: {
      header: "Anfrage",
      nPositions: "keine Positionen | {n} Position | {n} Positionen",
      nRelevantPositions:
        "keine relevante Position | {n} relevante Position | {n} relevante Positionen",
    },
    manufacturerTypes: {
      header: "Fabrikate",
    },
    files: {
      header: "Dateien",
      inquiry: "Anfragedateien",
      quotation: "Angebotsdateien",
      upload: "Hochgeladene Dateien",
      downloadFile: "{filename} herunterladen",
    },
    quotationFiles: {
      header: "Angebotsdateien",
      finalQuotation: "Angebot",
      downloadFile: "Angebot herunterladen",
      isAttachment: "Anhang",
      errors: {
        FILE_TOO_LARGE:
          "Ihre Organisation lässt nur Dateien bis zu einer Größe von {maxSize} KB zu",
        INVALID_FILE_EXTENSION:
          "Ihre Organisation lässt nur folgende Dateitypen zu: {allowedExtensions}",
        UNKNOWN_ERROR: "Unbekannter Fehler beim Hochladen der Angebotsanlage",
      },
      uploadFile: "Datei hochladen",
      removeFile: "Datei löschen",
      removeFileConfirmation:
        "Sind Sie sicher, dass Sie die Datei '{filename}' löschen möchten?",
      removeFileOk: "Datei löschen",
      removeFileCancel: "Abbrechen",
    },
    editPositionsButton: {
      label: "Positionen",
    },
    cannotEditInStatus: "Kann im Status '{status}' nicht bearbeitet werden",
    finalizedBanner: {
      title: "Anfrage abgeschlossen",
      message:
        "Diese Anfrage ist abgeschlossen und kann nicht mehr bearbeitet werden.",
    },
    analyzingBanner: {
      title: "Anfrage wird analysiert",
      message:
        "Diese Anfrage wird gerade analysiert. Einige Werte können noch nicht bearbeitet werden.",
    },
    analysisErrorBanner: {
      title: "Analyse fehlgeschlagen",
      message:
        "Die Analyse dieser Anfrage ist fehlgeschlagen. Bitte laden Sie die Anfrage erneut.",
    },
    possibleDuplicatesBanner: {
      title: "Mögliche Duplikate",
      possibleDuplicateOf: "Diese Anfrage ist möglicherweise eine Kopie von",
      positionsCount: "(0 Positionen) | (1 Position) | ({n} Positionen)",
    },
    preferredManufacturers: {
      header: "Präferierte Hersteller",
      noPreferredManufacturers: "Keine präferierten Hersteller",
      nPreferredManufacturers:
        "Kein präferierter Hersteller | Ein präferierter Hersteller | {n} präferierte Hersteller",
      select: "Hersteller auswählen",
    },
    inquiryHistory: {
      title: "Aktivität",
      events: {
        CREATED: "{user} hat die Anfrage erstellt",
        STATUS_CHANGED:
          "{user} hat den Status der Anfrage von {oldStatus} auf {newStatus} geändert",
        EXPORTED: "{user} hat die Anfrage exportiert",
        FINALIZED: "{user} hat die Anfrage abgeschlossen",
        MOVED_TO_ANOTHER_INBOX:
          "{user} hat die Anfrage in den Posteingang {targetInboxName} verschoben",
        ASSIGNED_USER: {
          other: "{user} hat diese Anfrage {assignedUserName} zugewiesen",
          self: "{user} hat diese Anfrage sich selbst zugewiesen",
          none: "{user} hat die Zuweisung dieser Anfrage aufgehoben",
        },
        COPIED: "{user} hat die Anfrage nach {copyShortCode} kopiert",
        CREATED_AS_COPY:
          "{user} hat die Anfrage als Kopie von {originalShortCode} erstellt",
        COMMENT: "{user} hat einen Kommentar hinterlassen",
      },
      unknownUser: "Unbekannter Benutzer",
      systemUser: "kinisto",
      unknownInbox: "(gelöschter Posteingang)",
      newComment: {
        placeholder: "Kommentar hinterlassen ...",
        publish: `Kommentar abschicken (${cmdOrCtrlSymbol}+Enter)`,
      },
      editComment: "Kommentar bearbeiten",
      deleteComment: "Kommentar löschen",
      confirmDeleteComment: {
        title: "Kommentar löschen",
        message: "Soll dieser Kommentar wirklich gelöscht werden?",
        ok: "Löschen",
      },
      editedAt: "Bearbeitet {date}",
      noUserFound: "Kein Benutzer gefunden",
    },
    inquirySubscribeButton: {
      subscribe: "Benachrichtigungen aktivieren",
      unsubscribe: "Benachrichtigungen deaktivieren",
    },
    analyzedInDifferentInbox: {
      title: "Analyse aus anderem Ordner",
      message:
        "Diese Anfrage wurde in einem anderen Ordner analysiert. Sie können die Analyse dieser Anfrage erneut starten, dabei werden dann nur Anfragen aus dem aktuellen Ordner berücksichtigt. Schon existierende Positionen werden dabei gelöscht.",
      reanalysisButton: "Positionen löschen und erneut analysieren",
    },
  },
  inquiryPositionsPage: {
    title: "Positionen",
    backToInquiryButton: "Zurück zur Anfrage",
    toggleOfferedButton: {
      addToOffer: "Position anbieten",
      removeFromOffer: "Position nicht anbieten",
    },
    positionsFilter: {
      text: "Positionen finden",
      offered: "anzubieten",
      notCompleted: "nicht fertig",
      supplierRfqs: "Lieferantenanfragen",
    },
    positionsTable: {
      editPosition: "Position bearbeiten",
      nPositions: "{positions} Positionen",
      nSelected: "{selected} / {positions} ausgewählt",
    },
    offerPositionGroup: {
      expand: "ausklappen",
      expandAdditionally: "zusätzlich ausklappen",
      shift: "Umschalt",
      click: "Klick",
      collapse: "einklappen",
      helpText:
        "Klicken zum auswählen, Eingabe drücken zum editieren, mit Pfeiltasten hoch/runter navigieren",
      missingAmount: "Position(en) mit fehlenden Mengen vorhanden.",
      missingPrice: "Position(en) mit fehlenden Preisen vorhanden.",
      finalize: "fertigstellen",
      unfinalize: "als nicht fertig markieren",
      confirmDelete: {
        title: "Position löschen",
        message:
          "Sind Sie sicher, dass Sie die Position unwiderbringlich löschen möchten?",
      },
      addSupplierRfq: "Lieferanten anfragen",
      removeSupplierRfq: "Lieferantenanfrage abbrechen",
      existingSupplierRfqs: "Folgende Lieferanten wurden bereits angefragt:",
      copyProducts: "Produkte kopieren",
      pasteProducts: "Produkte einfügen",
      pasteProductsConfirmation:
        "Die LV-Position enthält bereits Produkte. Möchten Sie diese überschreiben?",
      cancel: "Abbrechen",
      totalPrice: "Gesamtpreis LV-Position",
      totalMargin: "Gesamt-Marge LV-Position",
      configureVariant: "Variante konfigurieren",
    },
    offerPosition: {
      addProductPlaceholder: "Produkt hinzufügen ...",
    },
    productVariantConfiguratorPopup: {
      title: "Konfiguration",
      addonUpdateError: "Fehler beim Aktualisieren der Position {addonKey}",
    },
    addOfferPositionGroup: "Position hinzufügen",
    toSupplierRequestsButton: "Lieferantenanfragen",
    positionsSummaryFooter: {
      count: "Angebotspositionen",
      margin: "Gesamt-Marge",
      price: "Gesamtpreis",
    },
  },
  supplierRfqsPage: {
    title: "Lieferantenanfragen",
    backToInquiryButton: "Zurück zur Anfrage",
    openInquiry: "Anfrage öffnen",
    editPositionsButton: "Positionen bearbeiten",
    pagination: {
      rowsPerPage: "Anfragen pro Seite",
      count: "{from}-{to} von {total}",
    },
    filterMenuButton: {
      searchBuildingProject: "Bauvorhaben finden",
      searchShortCode: "TE-123",

    },
    attachments: {
      attachExcerpts: "LV-Auszüge anhängen",
      attachOriginalBoq: "Komplette LV(s) anhängen",
      excerpt: "Auszug",
    },
    send: "Absenden",
    sentAt: "Gesendet am {sentAt}",
    markAsAnswered: "Als beantwortet markieren",
    markAsUnanswered: "Als nicht beantwortet markieren",
    deleteSupplierRfq: "Lieferantenanfrage löschen",
    numSupplierRfqs:
      "Keine Lieferantenanfragen vorhanden | Eine Lieferantenanfrage | {numSupplierRfqs} Lieferantenanfragen",
    confirmDeleteSentSupplierRfq: {
      title: "Gesendete Lieferantenfrage löschen",
      message:
        "Diese Lieferantenfrage wurde bereits gesendet. Wollen Sie sie wirklich löschen?",
    },
    addToSupplierContacts: "Lieferantenkontakt hinzufügen",
    editSupplierContact: "Lieferantenkontakt bearbeiten",
  },
  email: {
    subject: "Betreff",
    from: "von",
    to: "an",
    cc: "cc",
    bcc: "bcc",
    sender: "Absender",
  },
  inquiry: {
    buildingProject: "Bauvorhaben",
    createdAt: "Eingang",
    requestedSubmissionDate: "Abgabe",
    requestedCustomerSubmissionDate: "Submission",
    buildingProjectId: "Bauvorhaben-ID",
    customerId: "Kunden-ID",
    shortCode: "Anfrage-Kürzel",
    tag: "Tag",
    tags: "Tags",
  },
  inbox: {
    label: "Ordner",
    shortCode: "Kürzel",
    intakeAddress: "Eingangsadresse",
  },
  tag: {
    label: "Name",
    color: "Farbe",
  },
  customStatus: {
    name: "Name",
    color: "Farbe",
    originalStatus: "Originalstatus",
  },
  user: {
    email: "E-Mail",
    firstName: "Vorname",
    lastName: "Nachname",
    password: "Passwort",
  },
  supplierContact: {
    email: "E-Mail",
    firstName: "Vorname",
    lastName: "Nachname",
    jobTitle: "Jobtitel",
    notes: "Notizen",
  },
  product: {
    variant: "Variante",
    listPrice: "Listenpreis",
    vatTaxRate: "Steuersatz",
    group: "Gruppe",
    searchKeywords: "Suchbegriffe",
  },
  productUnit: {
    LUMPSUM: "psch.",
    PERHOUR: "pro Std.",
    PERTOUR: "pro Tour",
    MINUTE: "Min.",
    HOUR: "Std.",
    DAY: "Tg.",
    WEEK: "Wo.",
    MONTH: "Mon.",
    YEAR: "Jr.",
    GRAM: "g",
    KILOGRAMM: "kg",
    TON: "t",
    MILLIMETER: "mm",
    CENTIMETER: "cm",
    CENTIMETER2: "cm²",
    METER: "m",
    METER2: "m²",
    METER3: "m³",
    KILOMETER: "km",
    LITRE: "L",
    LITER: "L",
    PIECE: "St.",
    METER_WEEK: "m-Wo.",
    METER2_WEEK: "m²-Wo.",
    PIECE_WEEK: "St.-Wo.",
    PALLET: "Paletten",
    SLAB: "Platten",
    PACK: "Pack",
    CARTON: "Kartons",
    ROLL: "Rollen",
    BAG: "Beutel",
    BOARD: "Tafeln",
    BUNDLE: "Bund",
    BUCKET: "Eimer",
    SACK: "Sack",
    SET: "Satz",
    PAIR: "Paar",
    SHEET: "Bahnen",
    CAN: "Dosen",
    PACKAGE: "Pakete",
    BOTTLE: "Flaschen",
    BLOCK: "Block",
    CANISTER: "Kanister",
    PINT_US: "Pint US liquid",
    ROD: "Stangen",
    BIGBAG: "BigBag",
    BOX: "Boxen",
    KILOWATT: "kW",
    KILOWATT_PEAK: "kWP",
    CRATE: "Kasten",
    METER_DAY: "m-Tag",
    RNG: "Ring",
    E_3_LITER: "ml",
    E_1_LITER: "100 ml",
    MPALLET: "Multipalette",
    LPALLET: "Großpalette",
    BLISTER: "Blister",
    CONTAINER: "Container",
    E2_GRAM: "100 g",
    E3_GRAM: "kg",
    E5_GRAM: "100 kg",
    BARREL: "Fass",
    HOBBOCK: "Hobbock",
    TUBE: "Tube",
    E_1_METER3: "dm³",
    ZENTNER: "Zentner",
    DRUM: "Trommel",
    LAYER: "Lage",
    FITTINGS: "Garnitur",
    DISPLAY: "Display",
    LOAD: "Ladung",
    CARTRIDGE: "Kartusche",
    LARGE_BUNDLE: "Großbund",
    STACK: "Hub",
    TRUCKLOAD: "Zug",
  },
  offerPosition: {
    marginCannotBeGreaterThan100: "Marge größer 100%",
    discountCannotBeGreaterThan100: "Rabatt größer 100%",
    unitPrice: "Einzelpreis",
    totalPrice: "Gesamtpreis",
    totalPriceAlternative: "Gesamtpreis (optional)",
    discountRate: "Rabatt",
    margin: "Marge",
    listPrice: "Listenpreis",
    purchasePrice: "Einstandspreis",
    unitPriceUnknown: "Preis für Mengeneinheit unbekannt",
  },
  inboxPage: {
    notFound: "Dieser Ordner scheint nicht zu existieren :-(",
    filterMenuButton: {
      searchShortCodeId: "AN-123",
      searchSender: "Absender finden",
      searchBuildingProject: "Bauvorhaben finden",
      searchBuildingProjectId: "Bauvorhaben-ID finden",
      searchCustomer: "Kunde finden",
    },
    pagination: {
      rowsPerPage: "Anfragen pro Seite",
      count: "{from}-{to} von {total}",
    },
    sortOptions: {
      id: "ID",
      created_at: "Eingangsdatum",
      requested_submission_date: "Abgabedatum",
      requested_customer_submission_date: "Submission",
      offered_positions: "Angebotene Positionen",
      progress: "Fortschritt",
      status: "Status",
      assigned_user: "Benutzer",
    },
    ascending: "aufsteigend",
    descending: "absteigend",
    editPositions: "Positionen bearbeiten",
  },
  multiTagSelect: {
    searchTag: "Tag finden",
  },
  contactDialog: {
    title: "Kontakt",
    text: "Für Fragen und Anregungen wenden Sie sich bitte an {email}.",
    emailAddress: "support{'@'}kinisto.com",
  },
  keyboardShortcutsDialog: {
    title: "Tastaturkürzel",
    positionsPage: "LV-Bearbeitung",
    descriptions: {
      offer: "Position anbieten / nicht anbieten",
      markFinished:
        "Position als abgeschlossen / nicht abgeschlossen markieren",
      selectPrevious: "Vorherige Position auswählen",
      selectNext: "Nächste Position auswählen",
      selectPreviousAdditionally: "Vorherige Position zusätzlich auswählen",
      selectNextAdditionally: "Nächste Position zusätzlich auswählen",
      selectProduct: "Produkt auswählen",
      unselect: "Positionsauswahl aufheben",
      expand: "Position zusätzlich ausklappen",
      expandOnly: "Position ausklappen (nur diese)",
      collapse: "Position einklappen",
      jumpToNextInput: "Zur nächsten Eingabe springen",
    },
  },
  setPasswordForm: {
    passwordStrength: {
      weak: "Zu schwaches Passwort",
      ok: "Fast gut genug ...",
      strong: "Starkes Passwort",
    },
  },
  notificationsPage: {
    label: "Benachrichtigungen",
    unreadNotifications:
      "Keine ungelesenen Benachrichtigungen | Eine ungelesene Benachrichtigung | {unreadCount} ungelesene Benachrichtigungen",
    markAllAsRead: "Alle als gelesen markieren",
    openInquiry: "Anfrage öffnen",
    markAsRead: "Als gelesen markieren",
    markAsUnread: "Als ungelesen markieren",
  },
  shortMonthsFrom1: {
    1: "Jan",
    2: "Feb",
    3: "Mär",
    4: "Apr",
    5: "Mai",
    6: "Jun",
    7: "Jul",
    8: "Aug",
    9: "Sep",
    10: "Okt",
    11: "Nov",
    12: "Dez",
  },
  sidebarMenu: {
    supplierRfqs: "Lieferantenanfragen",
    inquiries: "Anfragen",
    quotations: "Angebote",
    masterData: "Stammdaten",
    suppliers: "Lieferanten",
  },
  Suppliers: "Lieferanten",
  supplierSelectMenu: {
    searchPlaceholder: "Lieferanten finden",
    alreadySentTooltip: "Bereits gesendete Lieferantenanfrage",
    goToSupplierRfq: "Lieferantenanfrage öffnen",
  },
  supplierSelectButton: {
    tooltip:
      "{numSupplierRfqsAnswered} von {numSupplierRfqs} Lieferantenanfragen beantwortet",
  },
  supplierDialog: {
    actions: "Aktionen",
    cannotEditImported: "Importierte Kontakte können nicht bearbeitet werden",
    cannotDeleteImported: "Importierte Kontakte können nicht gelöscht werden",
    deleteContact: "Kontakt löschen",
    deleteContactMessage:
      "Sind Sie sicher, dass Sie den Kontakt {name} löschen möchten?",
    addContact: "Kontakt hinzufügen",
    cancel: "Abbrechen",
    ok: "Löschen",
  },
  asyncMultiSelectMenu: {
    minSearchLength: "Bitte mindestens {minSearchLength} Zeichen eingeben",
  },
  supplier: {
    label: "Lieferant",
    nLabel: "Keine Lieferanten | Ein Lieferant | {n} Lieferanten",
    contacts: "Kontakte",
  },
  supplierRfq: {
    sentAt: "Gesendet",
    dueDate: "Fällig",
    draft: "Entwurf",
    sent: "Gesendet",
    status: "Status",
    assignedUser: "Zugewiesener Benutzer",
    inquiry: "Anfrage",
    recipientsTo: "An",
    recipientsCc: "Cc",
    recipientsBcc: "Bcc",
    subject: "Betreff",
    body: "Nachricht",
  },
  supplierRfqStatus: {
    DRAFT: "Entwurf",
    SENT: "Gesendet",
    ANSWERED: "Beantwortet",
  },
  objects: {
    inquiry: "Anfrage",
  },
  filterChips: {
    assignedToMe: "Mir zugewiesen",
    nUsers: "{n} Benutzer",
    nInboxes: "{n} Ordner",
  },
  gaebViewer: {
    billOfQuantities: "Leistungsverzeichnis",
    position: "Position",
    description: "Beschreibung",
    amount: "Menge",
    contractualRegulations: "Vertragsbestimmungen",
    supportEmailSubject: "Fehler in GAEB-Anzeige",
    markupItem: "Zuschlagsposition",
    performanceDescription: "Ausführungsbeschreibung {id}",
    remark: "Anmerkung",
    buildingProjectId: "Bauvorhaben-ID",
    ownerInformation: "Auftraggeber-Informationen",
    positions: "LV-Positionen",
    phone: "Tel.",
    fax: "Fax",
    email: "E-Mail",
    vatid: "USt.-ID",
    awardNo: "Vergabenummer",
    positionDetails: {
      provis: {
        WithoutTotal: "Bedarfsposition (nicht in Gesamtpreis enthalten)",
        WithTotal: "Bedarfsposition (in Gesamtpreis enthalten)",
      },
      lumpSumItem: "Pauschalposition",
      hourItem: "Stundenlohnarbeiten",
      keyItem: "Schwerpunktposition",
      markupItem: "Zuschlagsposition",
      quantityTbd: "Freie Menge",
      performanceDescriptionReference:
        "Ausführung gemäß Ausführungsbeschreibung {id}",
      alternativePosition: "Alternativposition",
      alternativePositionReference:
        "Alternativposition Gruppe {groupNumber} Alternative {seriesNumber}",
      addendumReference: "Nachtrag Nr. {addendumNumber}",
      isReferenceDescription: "Referenzbeschreibung",
      isRepetitionDescription: "Wiederholungsbeschreibung",
    },
  },
  changelog: {
    title: "Neues in kinisto",
  },
  organization: {
    details: {
      companyName: "Firmenname",
      street: "Straße",
      postCode: "Postleitzahl",
      region: "Bundesland",
      town: "Ort",
      country: "Land",
      countryCode: "Landescode",
      phoneNumber: "Telefonnummer",
      faxNumber: "Faxnummer",
      email: "E-Mail",
      website: "Website",
      vatTaxId: "Steuer-ID",
      taxId: "Steuernummer",
      registerId: "Handelsregister",
      managingDirectors: "Geschäftsführer",
      bankName: "Bankname",
      bankBic: "BIC",
      bankIban: "IBAN",
      currency: "Währung",
      logo: "Logo",
      quotationSalutation: "Anschreiben",
      quotationClosing: "Grußformel",
      initialQuotationSerialId: "Erste Angebotsnummer",
    },
  },
  createDraftQuotationDialog: {
    title: "Angebot erstellen",
    button: "Angebot erstellen",
    recipientName: "Kundenname",
    recipientAddress: "Kundenanschrift",
    salutation: "Anschreiben",
    closing: "Grußformel",
    discount: "Rabatt",
    createDraftPdf: "Angebotsentwurf herunterladen (PDF)",
    createDraftP94: "Angebotsentwurf herunterladen (GAEB p94)",
    createDraftX94: "Angebotsentwurf herunterladen (GAEB x94)",
    createFinalPdf: "Angebot abschließen und herunterladen (PDF)",
    createFinalP94: "Angebot abschließen und herunterladen (GAEB p94)",
    createFinalX94: "Angebot abschließen und herunterladen (GAEB x94)",
    details: "Angebotdetails",
    createDraftTooltip:
      'Das Angebot wird als "Entwurf" markiert. Die Anfrage wird dadurch nicht abgeschlossen.',
    createFinalTooltip:
      "Die Anfrage wird abgeschlossen und kann anschließend nicht mehr bearbeitet werden.",
    notFinalizableReasons: {
      MISSING_RECIPIENT_NAME: "Kundenname fehlt",
      MISSING_RECIPIENT_ADDRESS: "Kundenanschrift fehlt",
      MISSING_PRICES: "Einige Produkte haben keine Preise",
      MISSING_VAT_TAX_RATES: "Einige Produkte haben keine Steuersätze",
      MISSING_AMOUNTS: "Einige Produkte haben keine Mengen",
    },
  },
  quotationsPage: {
    title: "Angebote",
    downloadQuotation: "Angebot herunterladen",
    pagination: {
      rowsPerPage: "Angebote pro Seite",
    },
  },
  quotation: {
    serialId: "Angebotsnummer",
    createdAt: "Erstellt am",
    acceptedOrDeclinedAt: "Angenommen/Abgelehnt am",
    acceptedAt: "Angenommen am",
    declinedAt: "Abgelehnt am",
    totalPriceNet: "Netto Angebotsvolumen",
    positionCount: "Positionen",
    positionCountNum: "{count} Pos.",
    status: "Status",
    customer: "Kunde",
    assignedUser: "Benutzer",
  },
  quotationStatus: {
    ACCEPTED: "Angenommen",
    DECLINED: "Abgelehnt",
    CREATED: "Erstellt",
  },
  sortMenuButton: {
    ascending: "aufsteigend",
    descending: "absteigend",
  },
  automationRule: {
    setTag: "Tag zuweisen",
    conditions: "Bedingungen",
    where: "wo",
    action: "Aktion",
    operator: {
      AND: "und",
      OR: "oder",
    },
    checkCondition: {
      BOQ_TEXT_ICONTAINS: "Langtext enthält",
      BOQ_SHORT_TEXT_ICONTAINS: "Kurztext enthält",
    },
  },
  productGroup: {
    noGroup: "Andere Positionen",
  },
  productGroupDiscount: {
    noDiscounts: "Keine Rabatte gegeben",
    dontShowAgain: "Diese Warnung nicht mehr anzeigen",
    addDiscount: {
      label: "Rabatt hinzufügen",
      confirmMessage:
        "Der Rabatt für alle Angebotspositionen aus dieser Produktgruppe wird überschrieben?",
    },
    deleteDiscount: {
      confirmMessage:
        "Der Rabatt für alle Angebotspositionen aus dieser Produktgruppe wird zurückgesetzt.",
    },
    updateDiscount: {
      confirmMessage:
        "Der Rabatt für alle Angebotspositionen aus dieser Produktgruppe wird überschrieben.",
    },
  },
  supplierContactDialog: {
    titleAdd: "Lieferantenkontakt hinzufügen",
    titleEdit: "Lieferantenkontakt bearbeiten",
    okButtonAdd: "Hinzufügen",
    okButtonEdit: "Speichern",
    errors: {
      ADDRESS_EXISTS:
        "Diese E-Mail-Adresse ist bereits einem Kontakt zugeordnet.",
      ADDRESS_INVALID: "Ungültige E-Mail-Adresse",
    },
  },
  accountMenu: {
    superUser: "Super User",
    switchOrganization: "Organisation wechseln",
    organizationSettings: "Organisationseinstellungen",
    inviteAndManageMembers: "Mitglieder einladen und verwalten",
    accountSettings: "Accounteinstellungen",
    keyboardShortcuts: "Tastaturkürzel",
    contact: "Kontakt",
    help: "Hilfe",
    signOut: "Ausloggen",
  },
  switchOrganizationDialog: {
    noResults: "Keine Ergebnisse",
  },
  manufacturerSelectButton: {
    searchPlaceholder: "Hersteller finden",
    buttonLabel: "Hersteller auswählen",
  },
  changePreferredManufacturersDialog: {
    text: "Die KI-Produktvorschläge werden neu generiert, dies kann einige Sekunden dauern.",
    cancel: "Abbrechen",
    save: "Jetzt neu generieren",
  },
  manufacturerSelectDialog: {
    header: "Präferierte Hersteller auswählen",
    description:
      "Die ausgewählten Hersteller werden in den vorgeschlagenen Produkten bevorzugt. KI-Produktvorschläge werden neu generiert. Bereits bearbeitete LV-Positionen werden nicht verändert.",
    cancel: "Abbrechen",
    ok: "KI-Produktvorschläge neu generieren",
  },
  errors: {
    filenameTooLong: "Dateiname zu lang, maximal {maxLength} Zeichen erlaubt",
  },
  customer: {
    label: "Kunde",
  },
};
