<template>
  <q-icon name="sym_r_info">
    <q-tooltip>
      <div>
        {{
          $t(
            "settings.organization.companyInformation.quotationSalutationAndClosingVariables.description",
          )
        }}
      </div>
      <ul>
        <li v-for="(variable, key) in variables" :key="key">
          <b>{{ key }}</b> -
          {{ variable }}
        </li>
      </ul>
    </q-tooltip>
  </q-icon>
</template>

<script setup lang="ts">
import { useI18n } from "vue-i18n";

const { t } = useI18n();
const variables = {
  $building_project: t(
    "settings.organization.companyInformation.quotationSalutationAndClosingVariables.variables.$building_project",
  ),
  $first_name: t(
    "settings.organization.companyInformation.quotationSalutationAndClosingVariables.variables.$first_name",
  ),
  $last_name: t(
    "settings.organization.companyInformation.quotationSalutationAndClosingVariables.variables.$last_name",
  ),
};
</script>
