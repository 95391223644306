<template>
  <div class="col row no-wrap items-start gap-sm">
    <div class="col grid" :class="{ 'two-rows': useDiscounts && useGrossMargins }">
      <template v-if="useDiscounts">
        <number-input
          class="row-height col-1"
          :label="$t('offerPosition.listPrice')"
          :model-value="position.unitPrice"
          @update:model-value="updateUnitPrice"
          :precision="CURRENCY_PRECISION"
          :suffix="currencySymbol"
          :disabled="!position.product.isPriceAdjustable || disabled"
          v-autoselect
        />
        <number-input
          class="row-height col-2"
          :label="$t('offerPosition.discountRate')"
          :model-value="position.discountRate"
          @update:model-value="updateDiscount"
          :precision="DISCOUNT_PRECISION"
          suffix="%"
          :disabled="!useDiscounts || disabled"
          v-autoselect
          :validate="validateDiscount"
        />
      </template>
      <template v-if="organization?.useGrossMargins">
        <number-input
          class="row-height col-1"
          :class="{ 'row-2': useDiscounts }"
          :label="$t('offerPosition.purchasePrice')"
          :model-value="position.purchasePrice"
          @update:model-value="updatePurchasePrice"
          :suffix="currencySymbol"
          :precision="CURRENCY_PRECISION"
          :disabled="!position.product.isPriceAdjustable || disabled"
          v-autoselect
        />
        <number-input
          class="row-height col-2"
          :class="{ 'row-2': useDiscounts }"
          :label="$t('offerPosition.margin')"
          :model-value="margin"
          @update:model-value="updateMargin"
          suffix="%"
          :precision="MARGIN_PRECISION"
          :disabled="isMarginInputDisabled"
          :validate="validateMargin"
          v-autoselect
        />
      </template>
      <number-input
        v-if="useDiscounts || position.product.isPriceAdjustable"
        class="row-height col-3"
        :label="$t('offerPosition.unitPrice')"
        :model-value="useDiscounts ? discountedUnitPrice : position.unitPrice"
        @update:model-value="
          ($event) =>
            useDiscounts
              ? updateDiscountToMatchUnitPrice($event)
              : updateUnitPrice($event)
        "
        :suffix="currencySymbol"
        :precision="CURRENCY_PRECISION"
        :disabled="disabled"
        v-autoselect
      />
      <discounted-price-display
        v-else
        :position="position"
        class="plain-number col-3"
      />
    </div>
    <total-price-display
      :position="position"
      class="total-price-display plain-number"
    />
  </div>
</template>

<script setup lang="ts">
import NumberInput from "@/components/FloatingLabelInput/NumberInput.vue";
import TotalPriceDisplay from "@/components/InquiryPositionsPage/OfferPosition/TotalPriceDisplay.vue";
import {
  CURRENCY_PRECISION,
  DISCOUNT_PRECISION,
  MARGIN_PRECISION,
} from "@/config/constants";
import { useCurrentOfferPositionGroupsStore } from "@/stores/currentOfferPositionsGroups";
import { useCurrentOrganizationStore } from "@/stores/currentOrganization";
import { useNumberFormatsStore } from "@/stores/numberFormats";
import type { OfferPosition } from "@/types/offerPosition";
import type { OfferPositionGroup } from "@/types/offerPositionGroup";
import {
  calculateDiscountFromTargetUnitPrice,
  calculatePositionMargin,
  calculatePositionUnitPrice,
  calculateUnitPriceFromTargetMargin,
} from "@/utils/pricing";
import { storeToRefs } from "pinia";
import { computed } from "vue";
import { useI18n } from "vue-i18n";
import DiscountedPriceDisplay from "./DiscountedPriceDisplay.vue";

const props = defineProps<{
  group: OfferPositionGroup;
  position: OfferPosition;
  disabled: boolean;
}>();

const { organization } = storeToRefs(useCurrentOrganizationStore());

const store = useCurrentOfferPositionGroupsStore();

const { currencySymbol } = storeToRefs(useNumberFormatsStore());

const useDiscounts = computed(
  () =>
    organization.value?.usePositionDiscounts &&
    !props.position.product.useMarkupCalculation,
);

const useGrossMargins = computed(() => organization.value?.useGrossMargins);

const discountedUnitPrice = computed(() => {
  return calculatePositionUnitPrice(
    props.position.unitPrice,
    props.position.discountRate,
  );
});

const margin = computed(() => {
  return calculatePositionMargin(
    props.position.unitPrice,
    props.position.discountRate,
    props.position.purchasePrice,
  );
});

const isMarginInputDisabled = computed(() => {
  if (props.disabled) {
    return true;
  }
  if (useDiscounts.value) {
    return false;
  }

  if (props.position.product.isPriceAdjustable) {
    return false;
  }

  return true;
});

const updatePurchasePrice = (value: number | null) => {
  if (!props.position.product.isPriceAdjustable) {
    return;
  }

  if (
    value?.toFixed(CURRENCY_PRECISION) ===
    props.position.purchasePrice?.toFixed(CURRENCY_PRECISION)
  ) {
    return;
  }

  store.updateOfferPosition(props.group.id, props.position.id, {
    purchasePrice: value !== null ? Number(value) : null,
  });
};

const updateUnitPrice = (value: number | null) => {
  if (!props.position.product.isPriceAdjustable) {
    return;
  }

  if (
    value?.toFixed(CURRENCY_PRECISION) ===
    props.position.unitPrice?.toFixed(CURRENCY_PRECISION)
  ) {
    return;
  }

  store.updateOfferPosition(props.group.id, props.position.id, {
    unitPrice: value !== null ? Number(value) : null,
  });
};

const updateDiscount = (value: number | null) => {
  if (
    value?.toFixed(DISCOUNT_PRECISION) ===
    props.position.discountRate?.toFixed(DISCOUNT_PRECISION)
  ) {
    return;
  }

  if (validateDiscount(value) !== null) {
    return;
  }

  if (value !== null && isNaN(value)) {
    return;
  }

  store.updateOfferPosition(props.group.id, props.position.id, {
    discountRate: value !== null ? Number(value) : null,
  });
};

const updateDiscountToMatchUnitPrice = (value: number | null) => {
  const discountRate = calculateDiscountFromTargetUnitPrice(
    value !== null ? Number(value) : null,
    props.position.unitPrice,
  );

  if (validateDiscount(discountRate) !== null) {
    return;
  }

  if (discountRate !== null && isNaN(discountRate)) {
    return;
  }

  if (
    discountRate?.toFixed(DISCOUNT_PRECISION) ===
    props.position.discountRate?.toFixed(DISCOUNT_PRECISION)
  ) {
    return;
  }

  store.updateOfferPosition(props.group.id, props.position.id, {
    discountRate: discountRate,
  });
};

const updateMargin = (value: number | null) => {
  if (
    value?.toFixed(MARGIN_PRECISION) === margin.value?.toFixed(MARGIN_PRECISION)
  ) {
    return;
  }

  if (validateMargin(value) !== null) {
    return;
  }

  const newUnitPrice = calculateUnitPriceFromTargetMargin(
    props.position.purchasePrice,
    value !== null ? Number(value) : null,
  );

  if (useDiscounts.value) {
    updateDiscountToMatchUnitPrice(newUnitPrice);
  } else {
    updateUnitPrice(newUnitPrice);
  }
};

const { t } = useI18n();

const validateMargin = (value: number | null) => {
  if (value !== null && value >= 100) {
    return t("offerPosition.marginCannotBeGreaterThan100");
  }
  return null;
};

const validateDiscount = (value: number | null) => {
  if (value !== null && value > 100) {
    return t("offerPosition.discountCannotBeGreaterThan100");
  }
  return null;
};
</script>

<style scoped lang="scss">
.grid {
  display: grid;
  grid-template-columns: 1fr 8px 1fr 8px 1fr;
  row-gap: 8px;
  grid-row-gap: 8px;
  grid-template-rows: 1fr;

  &.two-rows {
    grid-template-rows: 1fr 1fr;
  }

  .col-1 {
    grid-column: 1;
  }

  .col-2 {
    grid-column: 3;
  }

  .col-3 {
    grid-column: 5;
  }

  .row-1 {
    grid-row: 1;
  }

  .row-2 {
    grid-row: 2;
  }
}

.row-height {
  height: 28px;
}

.plain-number {
  margin-bottom: 2px;
  align-self: flex-end;
}

.total-price-display {
  width: 130px;
  flex-shrink: 0;
}
</style>
