<template>
  <q-input
    ref="inputEl"
    v-model="localNotes"
    outlined
    autogrow
    type="textarea"
    bg-color="white"
    @focus="$emit('focus')"
    @blur="handleBlur"
    :disable="disabled"
    class="notes-input"
    input-class="notes-input-input"
    :placeholder="$t('Notes')"
    @keydown.stop.escape="inputEl?.blur()"
  />
</template>

<script setup lang="ts">
import { watchDebounced } from "@vueuse/core";
import { QInput } from "quasar";
import { onMounted, ref, watch } from "vue";

const props = defineProps<{
  notes: string | null;
  disabled: boolean;
}>();

const emit = defineEmits<{
  "update:notes": [notes: string | null];
  focus: [];
  blur: [];
}>();

const inputEl = ref<typeof QInput | null>(null);

const localNotes = ref(props.notes);
watch(
  () => props.notes,
  (newVal) => {
    localNotes.value = newVal;
  }
);

watchDebounced(
  localNotes,
  (value) => {
    emit("update:notes", value);
  },
  { debounce: 500 }
);

onMounted(() => {
  inputEl.value?.nativeEl.setAttribute("rows", "1");
});

function handleBlur() {
  emit("update:notes", localNotes.value);
  emit("blur");
}
</script>

<style lang="scss">
.notes-input {
  font-size: inherit !important;
  line-height: inherit !important;

  .q-field__control {
    min-height: 24px !important;
    padding: 0 4px !important;
  }

  .q-field__control-container {
    padding: 1px 0px !important;
  }

  &.q-field--focused .q-field__control {
    color: $neutral-10 !important;
  }
}

.notes-input-input {
  padding: 2px 0 !important;
  line-height: inherit !important;
  letter-spacing: inherit !important;
  min-height: 18px !important;
}
</style>
