<template>
  <q-card>
    <template v-if="items.length">
      <q-list separator dense>
        <q-item
          v-for="(item, index) in items"
          clickable
          v-ripple
          :key="index"
          :class="{ 'is-selected': index === selectedIndex }"
          @click="() => selectItem(index)"
          :active="index === selectedIndex"
          active-class="bg-neutral-2 text-neutral-10"
        >
          <user-display :user="item" />
        </q-item>
      </q-list>
    </template>
    <q-item v-else dense>
      <q-item-section>
        {{ $t("inquiryPage.inquiryHistory.noUserFound") }}
      </q-item-section>
    </q-item>
  </q-card>
</template>

<script setup lang="ts">
import UserDisplay from "@/components/User/UserDisplay.vue";
import type { User } from "@/types/user";
import { defineProps, ref, watch } from "vue";

interface CommandArgs {
  id: User;
}

const props = defineProps<{
  items: User[];
  command: (args: CommandArgs) => void;
}>();

const selectedIndex = ref(0);

watch(
  () => props.items,
  () => {
    selectedIndex.value = 0;
  },
);

const onKeyDown = ({ event }: { event: KeyboardEvent }) => {
  if (event.key === "ArrowUp") {
    selectedIndex.value =
      (selectedIndex.value + props.items.length - 1) % props.items.length;
    return true;
  }

  if (event.key === "ArrowDown") {
    selectedIndex.value = (selectedIndex.value + 1) % props.items.length;
    return true;
  }

  if (["Enter", "Tab"].includes(event.key)) {
    selectItem(selectedIndex.value);
    return true;
  }

  return false;
};

defineExpose({
  onKeyDown,
});

const selectItem = (index: number) => {
  const item = props.items[index];
  if (item) {
    props.command({ id: item });
  }
};
</script>
