<template>
  <floating-label-input
    v-model="inputValue"
    v-bind="otherProps"
    @input="preventNonNumeric"
    @focus="handleFocus"
    @blur="handleBlur"
    input-class="text-right"
    :has-warning="hasWarning"
    :error="errorMessage"
  />
</template>

<script setup lang="ts">
import { useDebouncedModel } from "@/composables/useDebouncedModel";
import { useNumberFormatsStore } from "@/stores/numberFormats";
import { omit } from "lodash";
import { storeToRefs } from "pinia";
import { computed, ref, watch } from "vue";
import type {
  FloatingLabelInputEmits,
  FloatingLabelInputProps,
} from "./FloatingLabelInput.vue";
import FloatingLabelInput from "./FloatingLabelInput.vue";
import { useAttrs } from "vue";

interface NumberInputProps extends FloatingLabelInputProps {
  debounceMs?: number;
  precision?: number;
  modelValue: number | null;
  validate?: (value: number | null) => string | null;
  hasWarning?: boolean;
}

const props = withDefaults(defineProps<NumberInputProps>(), {
  debounceMs: 500,
  modelValue: null,
  precision: 2,
  validate: () => null,
  hasWarning: false,
});

const attrs = useAttrs();

const otherProps = computed(() => {
  return {
    ...omit(props, ["modelValue", "debounceMs"]),
    ...attrs,
  };
});

const numberFormatsStore = useNumberFormatsStore();
const { decimalSeparator } = storeToRefs(numberFormatsStore);
const { numberToString, stringToNumber } = numberFormatsStore;

const preventNonNumeric = (event: Event) => {
  const input = event.target as HTMLInputElement;
  const value = input.value;

  const cleanupRegex = new RegExp(
    `[^\-0-9${decimalSeparator.value ?? ""}]`,
    "g",
  );
  const cleanedValue = value?.toString().replace(cleanupRegex, "") ?? null;
  input.value = cleanedValue;
};

const errorMessage = computed(() => {
  if (inputValue.value === null) return null;
  const number =
    typeof inputValue.value === "number"
      ? inputValue.value
      : stringToNumber(inputValue.value, props.precision);
  return props.validate(number);
});

interface NumberInputEmits extends FloatingLabelInputEmits {
  "update:modelValue": [value: number | null];
}

const emit = defineEmits<NumberInputEmits>();
const { internalValue, handleLocalInput, flushValue } = useDebouncedModel(
  props,
  (event, val) => emit(event, val),
  {
    defaultDebounceMs: 500,
  },
);

const isFocused = ref(false);
const inputValue = ref<string | number | null>(null);

// handle input changes -> pass on to debounced model
watch(inputValue, (value) => {
  if (value === null) {
    handleLocalInput(null);
  } else if (typeof value === "number") {
    handleLocalInput(isNaN(value) ? null : value);
  } else {
    handleLocalInput(stringToNumber(value, props.precision) ?? null);
  }
});

// handle external changes -> update input value only if it's not focused
watch(
  () => props.modelValue,
  (value) => {
    if (isFocused.value) return;
    if (value === null || isNaN(value)) {
      inputValue.value = null;
    } else {
      inputValue.value = numberToString(value, props.precision);
    }
  },
  {
    immediate: true,
  },
);

function handleFocus() {
  isFocused.value = true;
}

function handleBlur() {
  flushValue();
  isFocused.value = false;
  // update input value to match formatted value
  inputValue.value = numberToString(internalValue.value, props.precision);
  emit("blur");
}
</script>
