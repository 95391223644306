import { createSupplierContact, getSupplier, updateSupplierContact } from "@/api/supplier";
import { useRouteParams } from "@/composables/useRouteParams";
import type { SupplierContact, SupplierDetails } from "@/types/supplier";
import { defineStore, storeToRefs } from "pinia";
import { ref, watch } from "vue";
import { useCurrentSupplierRfqsStore } from "./currentSupplierRfqs";

export const useCurrentSupplierStore = defineStore("currentSupplier", () => {
  const supplier = ref<SupplierDetails | null>(null);

  const { organizationId, supplierRfqId } = useRouteParams();

  const { supplierRfqs } = storeToRefs(useCurrentSupplierRfqsStore());

  watch(
    () => [organizationId.value, supplierRfqId.value, supplierRfqs.value],
    loadSupplier,
    {
      immediate: true,
    }
  );

  async function loadSupplier() {
    if (!organizationId.value || !supplierRfqId.value || !supplierRfqs.value) {
      supplier.value = null;
      return;
    }

    const currentSupplierRfq = supplierRfqs.value.find(
      (supplierRfq) => supplierRfq.id === supplierRfqId.value
    );

    if (!currentSupplierRfq) {
      supplier.value = null;
      return;
    }

    supplier.value = await getSupplier(
      organizationId.value,
      currentSupplierRfq.supplier.id
    );
  }

  async function addContact(contact: Omit<SupplierContact, "id">) {
    if (!supplier.value) {
      throw new Error("Supplier not found");
    }

    const newContact = await createSupplierContact(
      supplier.value.id,
      contact
    );

    supplier.value.contacts.push(newContact);

    return newContact;
  }

  async function updateContact(contactId: number, contact: Partial<SupplierContact>) {
    if (!supplier.value) {
      throw new Error("Supplier not found");
    }

    const updatedContact = await updateSupplierContact(
      supplier.value.id,
      contactId,
      contact
    );

    supplier.value.contacts = supplier.value.contacts.map((c) =>
      c.id === contactId ? updatedContact : c
    );

    return updatedContact;
  }

  return {
    supplier,
    addContact,
    updateContact,
  };
});
