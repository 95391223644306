<template>
  <file-dropzone
    v-if="inquiry"
    :accept="organization?.quotationFileExtensions.join(', ') || ''"
    @drop="uploadQuotationFile"
    class="rounded-borders"
  >
    <template #overlay>
      <div>{{ $t("inquiryPage.quotationFiles.uploadFile") }}</div>
    </template>
    <q-card flat bordered v-if="inquiry.quotationFiles.length > 0">
      <q-list dense separator>
        <uploaded-file-item
          v-for="quotationFile in inquiry.quotationFiles"
          :key="quotationFile.id"
          :inquiry-id="inquiry.id"
          :quotation-file="quotationFile"
        />
      </q-list>
    </q-card>
    <div class="q-mt-xs row no-wrap justify-start">
      <q-btn
        flat
        dense
        color="neutral-8"
        icon="sym_r_add"
        size="md"
        @click.stop.prevent="fileInputEl?.click()"
      >
        {{ $t("inquiryPage.quotationFiles.uploadFile") }}
      </q-btn>
      <input
        type="file"
        multiple
        style="display: none"
        ref="fileInputEl"
        :accept="organization?.quotationFileExtensions.join(', ') || ''"
        @change="(event: any) => uploadQuotationFile(event.target?.files)"
      />
    </div>
  </file-dropzone>
</template>

<script setup lang="ts">
import { createQuotationFile } from "@/api/quotationFile";
import FileDropzone from "@/components/FileDropzone.vue";
import {
  DEFAULT_MAX_UPLOADED_FILE_SIZE_KB,
  MAX_UPLOADED_FILE_NAME_LENGTH,
} from "@/config/constants";
import { useCurrentInquiryStore } from "@/stores/currentInquiry";
import { useCurrentOrganizationStore } from "@/stores/currentOrganization";
import { type QuotationFile } from "@/types/quotation";
import { isAxiosError } from "axios";
import { storeToRefs } from "pinia";
import { useQuasar } from "quasar";
import { reactive, ref } from "vue";
import { useI18n } from "vue-i18n";
import UploadedFileItem from "./UploadedFileItem.vue";

const { inquiry } = storeToRefs(useCurrentInquiryStore());
const { organization } = storeToRefs(useCurrentOrganizationStore());
const fileInputEl = ref<HTMLInputElement | null>(null);

async function uploadQuotationFile(files: FileList) {
  const uploads = [];
  for (const file of files) {
    uploads.push(uploadSingleQuotationFile(file));
  }
  await Promise.all(uploads);
}

const q = useQuasar();
const { t } = useI18n();

async function uploadSingleQuotationFile(file: File) {
  if (!file) {
    return;
  }

  if (file.name.length > MAX_UPLOADED_FILE_NAME_LENGTH) {
    q.notify({
      message: t("errors.filenameTooLong", {
        maxLength: MAX_UPLOADED_FILE_NAME_LENGTH,
      }),
      color: "negative",
    });
    return;
  }

  const maxFileSizeKb =
    organization.value?.quotationFileMaxSizeKb ||
    DEFAULT_MAX_UPLOADED_FILE_SIZE_KB;
  if (file.size > maxFileSizeKb * 1024) {
    q.notify({
      message: t("inquiryPage.quotationFiles.errors.FILE_TOO_LARGE", {
        maxSize: maxFileSizeKb,
      }),
      color: "negative",
    });
    return;
  }
  const temporaryId = crypto.randomUUID();

  const tempQuotationFile: QuotationFile = reactive({
    id: -1,
    filename: file.name,
    fileFormat: null,
    isAttachment: true,
    temporaryId,
  });
  inquiry.value!.quotationFiles.push(tempQuotationFile);
  try {
    const newQuotationFile = await createQuotationFile(inquiry.value!.id, file);
    Object.assign(tempQuotationFile, newQuotationFile);
    tempQuotationFile.temporaryId = undefined;
  } catch (e: any) {
    if (isAxiosError(e) && e.response?.data) {
      const errorCode = e.response?.data?.[0];
      const errorMessage =
        t(`inquiryPage.quotationFiles.errors.${errorCode}`, {
          maxSize: organization.value!.quotationFileMaxSizeKb,
          allowedExtensions:
            organization.value!.quotationFileExtensions.join(", "),
        }) || t("inquiryPage.quotationFiles.errors.UNKNOWN_ERROR");
      q.notify({
        message: errorMessage,
        color: "negative",
      });
      inquiry.value!.quotationFiles = inquiry.value!.quotationFiles.filter(
        (file) => file.temporaryId !== temporaryId,
      );
    } else {
      throw e;
    }
  }
}
</script>

<style scoped>
.button-spacer {
  width: 25.15px;
}
</style>
