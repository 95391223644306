<template>
  <q-btn
    dense
    flat
    class="user-select-button"
    :disable="disabled"
    @click="handleClick"
  >
    <user-display :user="user" :icon-only="iconOnly" v-if="!isIndeterminate" />
    <template v-else>
      <q-avatar
        :class="{ 'q-mr-md': !iconOnly }"
        size="sm"
        color="neutral-8"
        text-color="white"
      >
        <q-icon name="sym_r_person" size="14px" />
      </q-avatar>
      <template v-if="!iconOnly">{{ $t("Assign") }}</template>
    </template>
    <user-select-menu
      :user="user"
      @update:user="
        (user) => $emit('update:user', user?.id == null ? null : user as User)
      "
      :store="store"
      :is-loading="isLoading"
    />
    <q-tooltip v-if="iconOnly">
      <user-label :user="user" v-if="!isIndeterminate" />
      <template v-else>{{ $t("Assign") }}</template>
    </q-tooltip>
  </q-btn>
</template>

<script setup lang="ts">
import { listInboxMembersAndNonMembers } from "@/api/user";
import { useRouteParams } from "@/composables/useRouteParams";
import type { User, UserWithRole } from "@/types/user";
import { computed, ref } from "vue";
import UserDisplay from "./UserDisplay.vue";
import UserLabel from "./UserLabel.vue";
import UserSelectMenu from "./UserSelectMenu.vue";

const props = withDefaults(
  defineProps<{
    user: User | null;
    inboxId: number;
    disabled?: boolean;
    iconOnly?: boolean;
    isIndeterminate?: boolean;
  }>(),
  {
    disabled: false,
    iconOnly: false,
    isIndeterminate: false,
  }
);

defineEmits<{
  (event: "update:user", user: User | null): void;
}>();

const isLoading = ref(false);
let hasLoaded = false;

const { organizationId } = useRouteParams();

async function handleClick() {
  if (hasLoaded) return;
  await loadUsers();
}

const users = ref<UserWithRole[]>([]);
const emptyUser = {
  id: null,
  email: "",
  firstName: "",
  lastName: "",
};
const usersWithEmptyUser = computed(() => [emptyUser, ...users.value]);
const refresh = () => {};
const store = {
  users,
  emptyUser,
  usersWithEmptyUser,
  refresh,
};

async function loadUsers() {
  if (isLoading.value) return;

  isLoading.value = true;
  try {
    const inboxUsers = await listInboxMembersAndNonMembers(
      organizationId.value,
      props.inboxId
    );

    users.value = inboxUsers.filter((user) => user.role !== null);
    hasLoaded = true;
  } finally {
    isLoading.value = false;
  }
}
</script>

<style lang="scss">
.user-select-button {
  // don't decorate content
  font-weight: inherit;
}
</style>
