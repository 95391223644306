<template>
  <div class="row items-center gap-x-sm">
    <FilterChip
      v-if="filterShortCodeId"
      :icon="icons.inquiry.shortCode"
      @remove="$emit('update:filterShortCodeId', null)"
    >
      {{ inbox?.shortCode }}-{{ filterShortCodeId }}
    </FilterChip>
    <FilterChip
      v-if="filterStatusStrings.length > 0"
      :icon="icons.inquiry.status"
      @remove="$emit('update:filterStatusStrings', [])"
    >
      {{ t("statuses", filterStatusStrings.length) }}
    </FilterChip>
    <FilterChip
      v-if="filterTagsIds.length > 0"
      :icon="icons.inquiry.tag"
      @remove="$emit('update:filterTagsIds', [])"
    >
      {{ t("tags", filterTagsIds.length) }}
    </FilterChip>
    <FilterChip
      v-if="filterUserIds.length > 0"
      :icon="icons.user.main"
      @remove="$emit('update:filterUserIds', [])"
    >
      {{ t("filterChips.nUsers", { n: filterUserIds.length }) }}
    </FilterChip>
    <FilterChip
      v-if="filterSender"
      :icon="icons.inquiry.sender"
      @remove="$emit('update:filterSender', null)"
    >
      {{ filterSender }}
    </FilterChip>
    <FilterChip
      v-if="filterBuildingProject"
      :icon="icons.inquiry.buildingProject"
      @remove="$emit('update:filterBuildingProject', null)"
    >
      {{ filterBuildingProject }}
    </FilterChip>
    <FilterChip
      v-if="filterBuildingProjectId"
      :icon="icons.inquiry.buildingProjectId"
      @remove="$emit('update:filterBuildingProjectId', null)"
    >
      {{ filterBuildingProjectId }}
    </FilterChip>
    <FilterChip
      v-if="filterCustomer"
      :icon="icons.customer"
      @remove="$emit('update:filterCustomer', null)"
    >
      {{ filterCustomer }}
    </FilterChip>
  </div>
</template>

<script setup lang="ts">
import FilterChip from "@/components/FilterChip.vue";
import icons from "@/config/icons";
import { useCurrentInboxStore } from "@/stores/currentInbox";
import { storeToRefs } from "pinia";
import { useI18n } from "vue-i18n";

defineProps<{
  filterStatusStrings: string[];
  filterTagsIds: number[];
  filterUserIds: (number | null)[];
  filterSender: string | null;
  filterBuildingProject: string | null;
  filterShortCodeId: string | null;
  filterBuildingProjectId: string | null;
  filterCustomer: string | null;
}>();

defineEmits<{
  "update:filterStatusStrings": [statuses: string[]];
  "update:filterUserIds": [userIds: (number | null)[]];
  "update:filterSender": [sender: string | null];
  "update:filterBuildingProject": [sender: string | null];
  "update:filterTagsIds": [tagIds: number[]];
  "update:filterShortCodeId": [shortCode: string | null];
  "update:filterBuildingProjectId": [buildingProjectId: string | null];
  "update:filterCustomer": [customer: string | null];
}>();

const { t } = useI18n();

const { inbox } = storeToRefs(useCurrentInboxStore());
</script>
