import { defineStore, storeToRefs } from "pinia";
import { computed } from "vue";
import { useI18n } from "vue-i18n";
import { useCurrentOrganizationStore } from "./currentOrganization";
import { DEFAULT_CURRENCY } from "@/config/defaults";

export const useNumberFormatsStore = defineStore("numberFormats", () => {
    const testNumber = 1234.5;
    const { locale } = useI18n();
    const { organization } = storeToRefs(useCurrentOrganizationStore());

    const decimalSeparator = computed(() => {
        const parts = new Intl.NumberFormat(locale.value).formatToParts(testNumber)
        const decimalPart = parts.find(part => part.type === 'decimal')
        return decimalPart ? decimalPart.value : "."
    })

    const thousandsSeparator = computed(() => {
        const parts = new Intl.NumberFormat(locale.value).formatToParts(testNumber)
        const groupPart = parts.find(part => part.type === 'group')
        return groupPart ? groupPart.value : ","
    })


    function numberToString(value: number | null, precision: number) {
        if (value === null) return null;

        return value.toLocaleString(locale.value, {
            minimumFractionDigits: precision,
            maximumFractionDigits: precision,
        });
    }

    function stringToNumber(value: string | null, precision: number): number | null {
        if (value === null) return null;

        if (!value.length) return null;

        const decimalString = convertToDecimalString(value);

        if (!isValidNumberString(decimalString)) {
            return null;
        }
        const number = parseFloat(decimalString);
        if (isNaN(number)) {
            return null;
        }

        // Round to precision
        return (
            Math.round(number * Math.pow(10, precision)) /
            Math.pow(10, precision)
        );
    }

    const convertToDecimalString = (value: string) => {
        let result = value;
        if (decimalSeparator.value !== ".") {
            result = value.replace(/\./g, "");
        }
        return result.replace(decimalSeparator.value, ".");
    };

    const isValidNumberString = (value: string) => {
        return value.match(/[^\-\d.]/g) === null;
    };

    const currencySymbol = computed(() => {
        // Create a formatter for the specified locale and currency
        const formatter = new Intl.NumberFormat(locale.value, {
            style: "currency",
            currency: organization.value?.details.currency ?? DEFAULT_CURRENCY,
        });

        // Format any arbitrary number (here, 0) and break it into parts
        const parts = formatter.formatToParts(0);

        // Find the part of type "currency"
        const currencyPart = parts.find(part => part.type === 'currency');

        // Return the symbol (or currency code depending on the locale), or an empty string if not found
        return currencyPart?.value ?? '';
    });

    return {
        decimalSeparator,
        thousandsSeparator,
        currencySymbol,
        numberToString,
        stringToNumber,
    }
});
