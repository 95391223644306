import type { QInput } from "quasar";
import type { DirectiveBinding } from "vue";

const selectOnFocus = ($event: FocusEvent) => {
  ($event.target as HTMLInputElement).select();
};

type onFocusCallback = (event: FocusEvent) => void;

const inputAutoselectDirective = {
  mounted(el: typeof QInput, binding: DirectiveBinding<onFocusCallback>) {
    const input = el.querySelector("input");
    if (binding.value) {
      input?.addEventListener("focus", binding.value);
    } else {
      input?.addEventListener("focus", selectOnFocus);
    }
  },
  unmounted(el: typeof QInput, binding: DirectiveBinding<onFocusCallback>) {
    const input = el.querySelector("input");
    if (binding.value) {
      input?.removeEventListener("focus", binding.value);
    } else {
      input?.removeEventListener("focus", selectOnFocus);
    }
  },
};

export default inputAutoselectDirective;
