<template>
  <div ref="wrapper" class="pdf-page-wrapper">
    <canvas ref="canvas" class="pdf-page-canvas" v-bind="canvasAttrs"></canvas>
    <div ref="textLayer" class="textLayer" />
    <slot
      v-if="hasRendered && !hasEmbeddedText"
      name="ocr-text-layer"
      :canvas-size="canvasSize"
    />
    <slot name="highlights" :canvasSize="canvasSize" />
    <slot name="aids" :canvasSize="canvasSize" />
    <slot name="focus-overlay" />
  </div>
</template>

<script setup lang="ts">
import { isVisible } from "@/utils/display";
import type {
  PageViewport,
  PDFPageProxy,
  RenderTask,
} from "pdfjs-dist";
import { RenderingCancelledException, TextLayer } from "pdfjs-dist";
import { computed, nextTick, onMounted, ref, watch } from "vue";
import type { CanvasSize } from "./types";

const props = defineProps<{
  page: PDFPageProxy;
  scale: number;
  rotation: number;
  scrollParent: HTMLElement;
}>();
const wrapper = ref<HTMLElement | undefined>(undefined);
const canvas = ref<HTMLCanvasElement | undefined>(undefined);
const textLayer = ref<HTMLElement | undefined>(undefined);

const hasEmbeddedText = ref<boolean>(false);

// Render the PDF in higher resolution that the canvas is displayed at
// to improve the quality of the displayed text
// (otherwise, text is blurry)
const RENDER_SCALING_FACTOR = 2;

const viewport = computed<PageViewport>(() =>
  props.page.getViewport({
    scale: props.scale,
    rotation:
      (props.page.rotate + // inherent page rotation defined in PDF file
        props.rotation) % // page rotation defined by user
      360,
  })
);

const canvasSize = computed<CanvasSize>(() => {
  const [pixelWidth, pixelHeight] = [
    RENDER_SCALING_FACTOR * viewport.value.width,
    RENDER_SCALING_FACTOR * viewport.value.height,
  ];
  return { width: pixelWidth, height: pixelHeight };
});

const canvasAttrs = computed(() => {
  const { width, height } = canvasSize.value;
  const style = `width: ${width / RENDER_SCALING_FACTOR}px; height: ${
    height / RENDER_SCALING_FACTOR
  }px;`;
  return {
    width,
    height,
    style,
  };
});

onMounted(renderOnVisibility);

function renderOnVisibility() {
  if (!wrapper.value) return;

  const options = {
    root: props.scrollParent,
    rootMargin: "10px",
    threshold: [0],
  };
  const observer = new IntersectionObserver(scheduleRender, options);
  observer.observe(wrapper.value);
}

const hasRendered = ref(false);
let renderTask: RenderTask | Promise<any> | undefined = undefined;
watch(
  () => [props.scale, props.page, props.rotation],
  () => {
    // Scale, page or rotation has changed - need to update the canvas
    if (renderTask) {
      if ((<RenderTask>renderTask).cancel) {
        (<RenderTask>renderTask).cancel();
      }
      renderTask = undefined;
    }

    hasRendered.value = false;

    scheduleRender();
  }
);

async function scheduleRender() {
  nextTick(renderPageIfVisible);
}

async function renderPageIfVisible() {
  if (!wrapper.value || !canvas.value || !textLayer.value) return;
  if (!isVisible(wrapper.value, props.scrollParent)) return; // page not scrolled into view
  if (hasRendered.value) return; // has already been rendered

  if (renderTask && (<RenderTask>renderTask).cancel) {
    (<RenderTask>renderTask).cancel(); // currently rendering
  }
  renderTask = undefined;

  hasRendered.value = false;

  /* Clear potential text selection since the text divs are re-built and the
   * selection becomes invalid */
  clearTextSelection();

  const canvasHasRendered = await renderCanvas(canvas.value);
  const textHasRendered = await renderText(textLayer.value);

  if (canvasHasRendered && textHasRendered) hasRendered.value = true;
}

async function renderCanvas(canvas: HTMLCanvasElement): Promise<boolean> {
  const canvasContext = canvas.getContext("2d");
  if (!canvasContext) return false;
  const renderContext = {
    canvasContext,
    viewport: viewport.value,
    transform: [RENDER_SCALING_FACTOR, 0, 0, RENDER_SCALING_FACTOR, 0, 0],
  };

  renderTask = props.page.render(renderContext);
  try {
    await renderTask.promise;
    return true;
  } catch (e) {
    /* Ignore rendering cancelled errors since that is expected, e.g. when changing
     * zoom levels multiple times in quick succession */
    if (!(e instanceof RenderingCancelledException)) throw e;
    return false;
  } finally {
    renderTask = undefined;
  }
}

async function renderText(textLayer: HTMLElement): Promise<boolean> {
  clearRenderedText(textLayer);

  const textContent = await props.page.getTextContent();

  hasEmbeddedText.value = textContent.items.length > 0;

  /* If the pdf has no text content, the OCR text ist rendered */
  if (!hasEmbeddedText.value) return true;

  const pdfJsTextLayer = new TextLayer({
    textContentSource: textContent,
    container: textLayer,
    viewport: viewport.value,
  });
  renderTask = pdfJsTextLayer.render();
  try {
    await renderTask;
    return true;
  } catch (e) {
    /* Ignore rendering cancelled errors since that is expected, e.g. when changing
     * zoom levels multiple times in quick succession */
    if (!(e instanceof RenderingCancelledException)) throw e;
    return false;
  } finally {
    renderTask = undefined;
  }
}

/** Remove previously rendered text */
function clearRenderedText(textLayer: HTMLElement | undefined) {
  if (!textLayer) return;
  textLayer.textContent = "";
}

function clearTextSelection() {
  const selection = window.getSelection();
  if (selection) selection.empty();
}
</script>

<style lang="scss">
.pdf-page-wrapper {
  display: flex;
  position: relative;
}
.pdf-page-canvas {
  box-shadow: 0 1px 5px rgb(0 0 0 / 20%), 0 2px 2px rgb(0 0 0 / 14%),
    0 3px 1px -2px rgb(0 0 0 / 12%);
  background-color: white;
}
</style>
