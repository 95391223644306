<template>
  <div>
    <input
      v-model="inputText"
      ref="inputEl"
      @keydown="handleKeypress"
      @blur="emitUpdate"
      :class="{
        'text-right': true,
        'number-input': true,
        'standalone-input': !embedded,
        'embedded-input': embedded,
        'full-width': true,
        'cursor-text': !disabled,
        highlighted,
      }"
      :disabled="disabled"
    />
    <q-tooltip v-if="highlighted"
      >{{ $t("numberInput.valueMissing") }}
    </q-tooltip>
  </div>
</template>

<script setup lang="ts">
import { useNumberFormatsStore } from "@/stores/numberFormats";
import { storeToRefs } from "pinia";
import { computed, nextTick, ref, watch } from "vue";

const props = defineProps<{
  value: number | null;
  precision: number;
  nullable: boolean;
  highlighted?: boolean;
  disabled?: boolean;
  embedded?: boolean;
}>();

const emit = defineEmits<{
  "update:value": [value: number | null];
}>();

const numberFormatsStore = useNumberFormatsStore();
const { numberToString, stringToNumber } = numberFormatsStore;
const { decimalSeparator } = storeToRefs(numberFormatsStore);

const localValue = ref(props.value);
watch(
  () => props.value,
  async () => {
    localValue.value = props.value;
    await nextTick();
    if (inputEl.value)
      inputEl.value.value = numberToString(props.value, props.precision) ?? "";
  },
);

defineExpose({
  focus: () => {
    inputEl.value?.focus();
  },
  select: () => {
    inputEl.value?.select();
  },
  blur: () => {
    inputEl.value?.blur();
  },
});

function emitUpdate() {
  emit("update:value", localValue.value);
  if (inputEl.value)
    inputEl.value.value =
      numberToString(localValue.value, props.precision) ?? "";
}

function handleKeypress(event: KeyboardEvent) {
  if (event.key === "Enter") {
    emitUpdate();
  }
  preventNonNumericInput(event);
}

function preventNonNumericInput(event: KeyboardEvent) {
  if (event.ctrlKey || event.metaKey || event.altKey) return;
  if (event.key.length > 1) return; // special key
  if (event.key == decimalSeparator.value) return;
  if (
    ["0", "1", "2", "3", "4", "5", "6", "7", "8", "9", "0"].includes(event.key)
  )
    return;

  event.stopPropagation();
  event.preventDefault();
}

const inputEl = ref<HTMLInputElement | null>(null);

const inputText = computed({
  get: () => {
    if (
      inputEl.value &&
      stringToNumber(inputEl.value.value, props.precision) === localValue.value
    )
      return inputEl.value.value;
    return localValue.value === null
      ? ""
      : numberToString(localValue.value, props.precision);
  },
  set: (value: string) => {
    if (value === "" && props.nullable) return null;
    const number = stringToNumber(value, props.precision);
    if (number === undefined) return;
    localValue.value = number;
  },
});
</script>

<style scoped lang="scss">
.cursor-text {
  cursor: text !important;
}
.embedded-input {
  border: none;
  outline: none;
}
</style>
