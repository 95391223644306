import type { OfferPosition } from "@/types/offerPosition";

export function calculatePositionUnitPrice(
  listPrice: number | null,
  discountRate: number | null,
) {
  if (listPrice === null) {
    return null;
  }
  return listPrice * (1 - (discountRate ?? 0) / 100);
}

export function calculatePositionTotalPrice(position: OfferPosition) {
  if (position.amount === null || position.unitPrice === null) {
    return null;
  }
  const unitPrice = calculatePositionUnitPrice(
    position.unitPrice,
    position.discountRate,
  );
  if (unitPrice === null) {
    return null;
  }

  return position.amount * unitPrice;
}

export function calculateGroupTotalPrice(positions: OfferPosition[]) {
  let sum = 0;
  for (const position of positions) {
    if (position.isAlternative) continue;
    const positionTotalPrice = calculatePositionTotalPrice(position);
    if (positionTotalPrice === null) {
      return null;
    }
    sum += positionTotalPrice;
  }
  return sum;
}

export function calculateGroupTotalPurchasePrice(positions: OfferPosition[]) {
  let sum = 0;
  for (const position of positions) {
    if (position.isAlternative) continue;
    if (position.purchasePrice === null || position.amount === null) {
      return null;
    }
    sum += position.purchasePrice * (position.amount ?? 0);
  }
  return sum;
}

export function calculatePositionMargin(
  listPrice: number | null,
  discount: number | null,
  purchasePrice: number | null,
) {
  if (listPrice === null || purchasePrice === null) {
    return null;
  }
  const positionUnitPrice = calculatePositionUnitPrice(listPrice, discount);
  if (positionUnitPrice === null) {
    return null;
  }
  if (positionUnitPrice === 0) {
    return 0;
  }
  return ((positionUnitPrice - purchasePrice) / positionUnitPrice) * 100;
}

export function calculateGroupTotalMargin(positions: OfferPosition[]) {
  const totalPrice = calculateGroupTotalPrice(positions);
  if (totalPrice === null) {
    return null;
  }
  const totalPurchasePrice = calculateGroupTotalPurchasePrice(positions);
  if (totalPurchasePrice === null) {
    return null;
  }
  return ((totalPrice - totalPurchasePrice) / totalPrice) * 100;
}

export function calculateDiscountFromTargetUnitPrice(
  targetUnitPrice: number | null,
  listUnitPrice: number | null,
) {
  if (targetUnitPrice === null || listUnitPrice === null) {
    return null;
  }
  return ((listUnitPrice - targetUnitPrice) / listUnitPrice) * 100;
}

export function calculateUnitPriceFromTargetMargin(
  purchasePrice: number | null,
  targetMargin: number | null,
) {
  if (purchasePrice === null || targetMargin === null) {
    return null;
  }
  return purchasePrice / (1 - targetMargin / 100);
}
