<template>
  <q-input
    ref="inputEl"
    v-model="stringValue"
    :mask="mask"
    :placeholder="placeholder"
    clearable
    :disable="disable"
    v-bind="$attrs"
    :error="errorMessage !== undefined"
    :error-message="errorMessage"
  >
    <template v-slot:append>
      <q-icon name="event" class="cursor-pointer">
        <q-popup-proxy cover transition-show="scale" transition-hide="scale">
          <q-date v-model="isoStringValue">
            <div class="row items-center justify-end">
              <q-btn v-close-popup :label="$t('Close')" color="primary" flat />
            </div>
          </q-date>
        </q-popup-proxy>
      </q-icon>
    </template>
  </q-input>
</template>

<script setup lang="ts">
import dateFormatStrings from "@/i18n/dateFormatStrings";
import { format, parse } from "date-fns";
import { computed, ref, watch } from "vue";
import { useI18n } from "vue-i18n";

const props = defineProps<{
  modelValue: Date | null | undefined;
  disable?: boolean;
}>();
const emit = defineEmits<{
  "update:model-value": [date: Date | null | undefined];
}>();

const { locale, t } = useI18n();
const dateFormat = computed<string>(() => {
  const format =
    dateFormatStrings[locale.value as keyof typeof dateFormatStrings];
  if (!format)
    throw new Error("No date format found for locale " + locale.value);
  return format;
});
const errorMessage = ref<string | undefined>(undefined);
const stringValue = ref<string | null>(
  props.modelValue ? format(props.modelValue, dateFormat.value) : null
);

watch(
  () => props.modelValue,
  (newValue) => {
    if (!newValue) {
      stringValue.value = null;
      return;
    }
    const formatted = format(newValue, dateFormat.value);
    if (formatted !== stringValue.value) {
      stringValue.value = formatted;
    }
  }
);

watch(stringValue, (value) => {
  if (props.disable) return;
  if (!value) {
    emit("update:model-value", null);
    return;
  }

  if (value.length < dateFormat.value.length) {
    // input not finished
    return;
  }

  const date = parse(value, dateFormat.value, new Date());
  if (!isValidDate(date)) {
    errorMessage.value = t("dateInput.notValidDate");
    return;
  }
  errorMessage.value = undefined;
  if (date.getTime() === props.modelValue?.getTime()) return;
  emit("update:model-value", date);
});

function isValidDate(date: Date): boolean {
  return date && date.getFullYear() >= 1900 && !isNaN(date.getTime());
}

const mask = computed(() => {
  if (!dateFormat.value) return undefined;
  return dateFormat.value.replace(/[a-zA-Z]/g, "#");
});

const placeholder = computed(() => {
  if (!dateFormat.value) return undefined;
  return dateFormat.value.replace(/[a-zA-Z]/g, "_");
});

const isoFormat = "yyyy/MM/dd";

const isoStringValue = computed<string | null>({
  get: () => {
    if (!props.modelValue) return null;
    return format(props.modelValue, isoFormat);
  },
  set: (value) => {
    if (!value) {
      emit("update:model-value", null);
      return;
    }
    const date = parse(value, isoFormat, new Date());
    emit("update:model-value", date);
  },
});
</script>

<style scoped lang="scss">
.date-input-el {
  width: 100%;
  border: none;
  background-color: transparent;

  &:focus {
    outline: none;
  }
}
</style>
