<template>
  <div>
    <div class="wrapper" :class="{ disabled, error: hasError, warning: hasWarning }">
      <div
        class="label"
        :class="{
          'float-label': floatLabel,
          focused: isFocused,
          disabled,
          error: hasError,
        }"
      >
        {{ label }}
      </div>
      <input
        ref="inputEl"
        :value="modelValue"
        :type="type"
        :class="inputClass"
        :disabled="disabled"
        @input="(event: any) => $emit('update:modelValue', event.target.value)"
        @focus="handleInputFocus"
        @blur="handleInputBlur"
        @keydown.esc="$refs.inputEl?.blur()"
      />
      <slot
        name="suffix"
        :show="showSuffix"
        :has-value="hasValue"
        :is-focused="isFocused"
      >
        <div v-if="showSuffix" class="suffix">{{ suffix }}</div>
      </slot>
    </div>
    <div v-if="error" class="text-2xs text-negative-5 q-mt-xs">{{ error }}</div>
  </div>
</template>

<script setup lang="ts">
import { computed, ref } from "vue";

export interface FloatingLabelInputProps {
  label: string;
  modelValue: string | number | null;
  type?: string;
  disabled?: boolean;
  inputClass?: string;
  autoselect?: boolean;
  suffix?: string;
  error?: string | null;
  hasWarning?: boolean;
}

export interface FloatingLabelInputEmits {
  "update:modelValue": [value: string | number | null];
  focus: [];
  blur: [];
}

const props = withDefaults(defineProps<FloatingLabelInputProps>(), {
  disabled: false,
  inputClass: "",
  autoselect: false,
  hasWarning: false,
});

const emit = defineEmits<FloatingLabelInputEmits>();

const isFocused = ref(false);
const hasError = computed(() => {
  return props.error !== null;
});
const hasValue = computed(() => {
  return (
    props.modelValue !== null &&
    props.modelValue !== undefined &&
    props.modelValue !== ""
  );
});
const floatLabel = computed(() => {
  return hasValue.value || isFocused.value;
});
const showSuffix = computed(() => {
  return props.suffix && (hasValue.value || isFocused.value);
});

const inputEl = ref<HTMLInputElement | null>(null);

function handleInputFocus() {
  isFocused.value = true;
  if (props.autoselect) {
    inputEl.value?.select();
  }
  emit("focus");
}

function handleInputBlur() {
  isFocused.value = false;
  emit("blur");
}
</script>

<style scoped lang="scss">
.wrapper {
  position: relative;
  background-color: white;
  outline: 1px solid $neutral-5;
  height: 22px;
  padding: 1px 4px;
  border-radius: 4px;
  margin-top: 6px;

  display: flex;
  flex-direction: row;
  flex-wrap: nowrap;
  align-items: center;
  justify-content: flex-start;

  > input {
    border: none;
    outline: none;
    background-color: transparent;
    width: 100%;
    flex-shrink: 1;
    padding: 0;
    margin: 0;
  }

  &:hover {
    outline-color: $primary;
    .label.float-label:not(.disabled) {
      color: $primary;
    }
  }

  &:focus-within {
    outline-color: $primary;
    outline-width: 2px;
  }

  &.error {
    outline: 2px solid $negative-5;
    .label.float-label:not(.disabled) {
      color: $negative-5;
    }
  }

  &.warning {
    outline: 2px solid $warning;
    .label.float-label:not(.disabled) {
      color: $warning !important;
    }
  }

  &.disabled {
    opacity: 0.8 !important;
    outline: 1px solid $neutral-5 !important;
  }
}

.label {
  position: absolute;
  pointer-events: none;

  top: 50%;
  transform: translateY(-50%);

  left: 4px;

  font-size: inherit;
  color: $neutral-7;
  z-index: 1000;
  transition:
    top 0.2s ease,
    transform 0.2s ease,
    font-size 0.2s ease;

  white-space: nowrap;
  text-overflow: ellipsis;
  max-width: 100%;
  overflow: hidden;

  &.float-label {
    top: 0;
    font-size: 12px;
    transform: translateY(-50%) scale(0.75);
    transform-origin: left top;
    background-color: white;
    padding: 0 2px;
    border-radius: 4px;

    &.focused:not(.disabled):not(.error) {
      color: $primary;
    }

    &.error {
      color: $negative-5;
    }
  }

  &.disabled {
    opacity: 1 !important;
  }
}

.suffix {
  margin-left: 4px;
  color: $neutral-7;
}
</style>
