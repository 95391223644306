<template>
  <header-bar secondary>
    <div class="ellipsis">
      {{ supplierRfq?.supplier?.name }}
    </div>
    <template #right>
      <q-btn
        v-if="supplierRfq?.status === 'DRAFT'"
        flat
        dense
        color="neutral-7"
        icon="sym_r_send"
        :disable="
          disableInput || !supplierRfq?.recipientsTo.length || isSending
        "
        :loading="isSending"
        @click="send"
      >
        <q-tooltip anchor="top right" self="bottom right" :offset="[0, 5]">{{
          $t("supplierRfqsPage.send")
        }}</q-tooltip>
      </q-btn>
      <div v-else-if="supplierRfq?.sentAt" class="text-neutral-7">
        {{
          $t("supplierRfqsPage.sentAt", {
            sentAt: $d(supplierRfq.sentAt, "shortDatetime"),
          })
        }}
      </div>
    </template>
  </header-bar>
  <div class="page-below-header-bar">
    <div class="loading-indicator-container">
      <q-linear-progress v-if="showLoading" indeterminate color="neutral-5" />
    </div>
    <div class="full-height column no-wrap">
      <div class="col full-width column no-wrap q-px-md gap-sm" v-if="supplier">
        <div class="column no-wrap">
          <multi-email-input
            :supplier-id="supplier.id"
            :disable="disableInput"
            :recipients="supplierRfq?.recipientsTo ?? []"
            :contacts="contactEmails"
            :label="$t('supplierRfq.recipientsTo')"
            @update:emails="update({ recipientsTo: $event })"
          />

          <multi-email-input
            :supplier-id="supplier.id"
            :disable="disableInput"
            :recipients="supplierRfq?.recipientsCc ?? []"
            :contacts="contactEmails"
            :label="$t('supplierRfq.recipientsCc')"
            @update:emails="update({ recipientsCc: $event })"
          />

          <multi-email-input
            :supplier-id="supplier.id"
            :disable="disableInput"
            :recipients="supplierRfq?.recipientsBcc ?? []"
            :contacts="contactEmails"
            :label="$t('supplierRfq.recipientsBcc')"
            @update:emails="update({ recipientsBcc: $event })"
          />
        </div>
        <q-separator />
        <input
          :value="supplierRfq?.subject"
          class="input-no-decoration subject-input"
          :placeholder="$t('supplierRfq.subject')"
          @blur="(e) => update({ subject: (e.target as HTMLInputElement).value })"
          :disabled="disableInput"
        />
        <q-separator />
        <div class="col q-pb-sm">
          <q-editor
            class="full-height supplier-rfq-body-editor"
            :toolbar="[
              ['left', 'center', 'right', 'justify'],
              ['bold', 'italic', 'underline', 'strike'],
              ['unordered', 'ordered', 'outdent', 'indent'],
              ['undo', 'redo'],
            ]"
            paragraph-tag="div"
            toolbar-text-color="neutral-7"
            v-model="localBody"
            :disable="disableInput"
            @blur="update({ body: localBody })"
          />
        </div>
        <div class="q-pb-md row items-start">
          <date-input
            dense
            outlined
            label="Abgabetermin"
            :model-value="supplierRfq?.dueDate"
            :disable="disableInput"
            @update:model-value="update({ dueDate: $event })"
            class="bg-white"
          />
        </div>
      </div>
      <q-separator />
      <div class="full-width column no-wrap q-pa-md gap-md bg-neutral-1">
        <supplier-rfqs-attachment-bar
          :supplier-rfq="supplierRfq"
          :disable="disableInput"
          @update:attach-original-boq="update({ attachOriginalBoq: $event })"
        />
      </div>
    </div>
  </div>
</template>

<script setup lang="ts">
import DateInput from "@/components/DateInput.vue";
import HeaderBar from "@/components/Header/HeaderBar.vue";
import MultiEmailInput from "@/components/SupplierRfqsPage/MultiEmailInput.vue";
import SupplierRfqsAttachmentBar from "@/components/SupplierRfqsPage/SupplierRfqsAttachmentBar.vue";
import { delayLoadingIndicator } from "@/composables/useLoadingIndicator";
import { useRouteParams } from "@/composables/useRouteParams";
import { useCurrentOrganizationUsersStore } from "@/stores/currentOrganizationUsers";
import { useCurrentSupplierStore } from "@/stores/currentSupplier";
import { useCurrentSupplierRfqsStore } from "@/stores/currentSupplierRfqs";
import type { SupplierRFQ } from "@/types/supplierRfq";
import { getDisplayName } from "@/utils/displayName";
import { watchDebounced } from "@vueuse/core";
import { storeToRefs } from "pinia";
import { computed, ref, watch } from "vue";

const { supplierRfqId } = useRouteParams();

const supplierRfq = computed(() =>
  supplierRfqs.value.find((rfq) => rfq.id === supplierRfqId.value)
);
const isLoading = ref(false);
const showLoading = delayLoadingIndicator(isLoading);
const disableInput = computed(() => supplierRfq.value?.status !== "DRAFT");

const { supplier } = storeToRefs(useCurrentSupplierStore());
const { users } = storeToRefs(useCurrentOrganizationUsersStore());
const contactEmails = computed(() => {
  const supplierContactEmails =
    supplier.value?.contacts.map((contact) => ({
      address: contact.address,
      displayName: getDisplayName(contact),
      supplierContact: contact,
      supplierContactId: contact.id,
      userId: null,
    })) ?? [];

  const userEmails = users.value.map((user) => ({
    address: user.email,
    displayName: getDisplayName(user),
    supplierContactId: null,
    supplierContact: null,
    userId: user.id,
  }));

  return [...supplierContactEmails, ...userEmails];
});

const store = useCurrentSupplierRfqsStore();
const { updateSupplierRfq } = store;
const { supplierRfqs } = storeToRefs(store);

async function update(data: Partial<SupplierRFQ>) {
  if (disableInput.value) return;
  await updateSupplierRfq(supplierRfqId.value, data);
}

const isSending = ref(false);

async function send() {
  isSending.value = true;

  try {
    await store.sendSupplierRfq(supplierRfqId.value);
  } finally {
    isSending.value = false;
  }
}

const localBody = ref(supplierRfq.value?.body ?? "");
watch(
  () => supplierRfq.value?.body,
  (newBody) => {
    if (newBody === localBody.value) return;
    localBody.value = newBody ?? "";
  }
);
watchDebounced(
  localBody,
  (newBody) => {
    if (newBody === supplierRfq.value?.body) return;
    update({ body: newBody });
  },
  {
    debounce: 1000,
  }
);
</script>

<style scoped lang="scss">
.loading-indicator-container {
  position: absolute;
  top: 0;
  left: 0;
  right: 0;
  z-index: 1000;
}

.subject-input {
  background: transparent;
}

.subject-header-bar {
  max-width: 50%;
}
</style>

<style lang="scss">
.supplier-rfq-body-editor {
  display: flex;
  flex-direction: column;
  flex-wrap: nowrap;
  height: 100%;

  .q-editor__toolbar {
    flex-shrink: 0;
  }

  .q-editor__content {
    flex-grow: 1;
    overflow-y: auto;
  }
}
</style>
