<template>
  <select-menu
    :selected-option="selectedUser"
    :options="options"
    :label-fn="(user) => user.email"
    :search-values="['email', 'lastName', 'firstName']"
    id-value="email"
    :search-placeholder="$t('Search user')"
    :is-loading="isLoading"
    @select="select"
    @show="store.refresh"
  >
    <template #option-label="{ option }">
      <user-display :user="option" />
    </template>
  </select-menu>
</template>

<script setup lang="ts">
import SelectMenu from "@/components/SelectMenus/SelectMenu.vue";
import { useCurrentInboxUsersStore } from "@/stores/currentInboxUsers";
import { useCurrentUserStore } from "@/stores/currentUser";
import type { StoreWithUsers } from "@/types/storeWithUsers";
import type { User, UserOrEmptyUser } from "@/types/user";
import { storeToRefs } from "pinia";
import { computed, type ComputedRef, type Ref } from "vue";
import UserDisplay from "./UserDisplay.vue";

const props = withDefaults(
  defineProps<{
    user: UserOrEmptyUser | null;
    store?:
      | StoreWithUsers
      | {
          users: Ref<UserOrEmptyUser[]>;
          usersWithEmptyUser: ComputedRef<UserOrEmptyUser[]>;
          emptyUser: UserOrEmptyUser;
          refresh: () => void;
        };
    isLoading?: boolean;
  }>(),
  {
    store: () => useCurrentInboxUsersStore(),
    isLoading: false,
  },
);

const emit = defineEmits<{
  "update:user": [user: UserOrEmptyUser | null];
}>();

const { user: currentUser } = storeToRefs(useCurrentUserStore());
const { users, usersWithEmptyUser } = (props.store as StoreWithUsers).$state
  ? storeToRefs(props.store as StoreWithUsers)
  : props.store;
const { emptyUser } = props.store;

const options = computed(() => {
  if (!currentUser.value) return usersWithEmptyUser.value;
  return [
    emptyUser,
    currentUser.value,
    ...users.value.filter(
      (u: UserOrEmptyUser) => u.email !== currentUser.value!.email,
    ),
  ];
});

const selectedUser = computed(() =>
  props.user === null ? props.store.emptyUser : props.user,
);

function select(user: UserOrEmptyUser) {
  if (!user.email.length || user.id === null) {
    // empty user
    emit("update:user", null);
  } else {
    emit("update:user", user as User);
  }
}
</script>
