<template>
  <div
    class="row no-wrap items-center full-width gap-sm positions-table-skeleton-row"
    v-for="i in 10"
    :key="i"
  >
    <div class="button-spacer-start">
      <q-skeleton type="QRadio" size="16px" />
    </div>
    <div class="positions-table-skeleton-id">
      <q-skeleton height="18px" :width="getRandomWidth(80, 90)" />
    </div>
    <div class="positions-table-skeleton-description col">
      <q-skeleton height="18px" :width="getRandomWidth(30, 60)" />
    </div>
    <div class="positions-table-skeleton-amount row justify-end">
      <q-skeleton height="18px" :width="getRandomWidth(60, 90)" />
    </div>
    <div class="button-spacer-end">
      <q-skeleton type="QToggle" size="16px" />
    </div>
  </div>
</template>

<script setup lang="ts">
function getRandomWidth(min: number, max: number) {
  const number = Math.floor(Math.random() * (max - min + 1)) + min;
  return `${number}%`;
}
</script>

<style scoped lang="scss">
.button-spacer-start {
  width: 33px;
}

.positions-table-skeleton-row {
  height: 32px;
  padding: 2px 16px;
  border-bottom: 1px solid $neutral-2;
}

.positions-table-skeleton-id {
  width: 60px;
}

.positions-table-skeleton-amount {
  width: 50px;
}

.button-spacer-end {
  width: 48px;
}
</style>
