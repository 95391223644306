<template>
  <q-menu
    :offset="[0, 4]"
    @hide="searchText = null"
    ref="menuRef"
    @keydown.escape.stop="menuRef?.hide()"
    @keydown.stop
  >
    <q-card
      @keydown.stop.prevent.down="selectNext"
      @keydown.stop.prevent.up="selectPrevious"
      @keydown.stop.prevent.enter="
        selectedIndex !== null && toggleOption(allOptionsInList[selectedIndex])
      "
    >
      <div class="q-px-sm">
        <q-input
          borderless
          dense
          :placeholder="props.searchPlaceholder"
          v-model="searchText"
          autofocus
        />
      </div>
      <q-separator />
      <div>
        <q-list dense class="q-py-xs">
          <template
            v-for="(option, index) in selectedOptionsInList"
            :key="option[idValue]"
          >
            <slot
              name="option"
              :option="option"
              :select="select"
              :index="index"
            >
              <multi-select-menu-item
                :isSelected="true"
                @toggle="toggleOption(option)"
                :focused="selectedIndex === index"
              >
                <template #label>
                  <slot name="option-label" :option="option">
                    {{ labelFn(option) }}
                  </slot>
                </template>
              </multi-select-menu-item>
            </slot>
          </template>
          <q-separator v-if="selectedOptionsInList.length > 0" />
          <template
            v-for="(option, index) in notSelectedOptionsInList"
            :key="option[idValue]"
          >
            <slot
              name="option"
              :option="option"
              :select="select"
              :index="index + selectedOptionsInList.length"
            >
              <multi-select-menu-item
                :isSelected="false"
                @toggle="toggleOption(option)"
                :focused="selectedIndex === index + selectedOptionsInList.length"
              >
                <template #label>
                  <slot name="option-label" :option="option">
                    {{ labelFn(option) }}
                  </slot>
                </template>
              </multi-select-menu-item>
            </slot>
          </template>
        </q-list>
      </div>
    </q-card>
  </q-menu>
</template>

<script
  setup
  lang="ts"
  generic="
    IdValue extends string,
    Option extends { [key in IdValue]: string | number }
  "
>
import { computed, ref } from "vue";
import MultiSelectMenuItem from "./MultiSelectMenuItem.vue";
import { useKeyboardSelection } from "./useKeyboardSelection";
import type { QMenu } from "quasar";

const props = withDefaults(
  defineProps<{
    selectedOptions: Option[];
    searchPlaceholder: string;
    idValue: IdValue;
    searchValues: (keyof Option)[];
    options: Option[];
    labelFn: (option: Option) => string;
    maxOptionsDisplayed?: number;
  }>(),
  { maxOptionsDisplayed: 10 },
);

const emit = defineEmits<{
  select: [option: Option];
  unselect: [option: Option];
  "update:selectedOptions": [options: Option[]];
}>();

const searchText = ref<string | null>(null);
const menuRef = ref<typeof QMenu | null>(null);

const filteredOptions = computed(() => {
  if (!searchText.value) {
    return props.options;
  }
  return props.options.filter((option) =>
    props.searchValues.some((value) =>
      option[value]
        .toString()
        .toLowerCase()
        .includes(searchText.value!.toLowerCase()),
    ),
  );
});

const selectedOptionsInList = computed(() =>
  filteredOptions.value.filter((option) => isSelectedOption(option)),
);

const notSelectedOptionsInList = computed(() =>
  filteredOptions.value
    .filter((option) => !isSelectedOption(option))
    .slice(0, props.maxOptionsDisplayed - selectedOptionsInList.value.length),
);

const allOptionsInList = computed(() => [
  ...selectedOptionsInList.value,
  ...notSelectedOptionsInList.value,
]);

const { selectedIndex, selectNext, selectPrevious } =
  useKeyboardSelection(allOptionsInList);

function isSelectedOption(option: Option) {
  const selectedIds = props.selectedOptions.map(
    (option) => option[props.idValue],
  );
  return selectedIds.includes(option[props.idValue]);
}

function toggleOption(option: Option) {
  if (isSelectedOption(option)) {
    unselect(option);
  } else {
    select(option);
  }
}

function select(option: Option) {
  emit("select", option);
  emit("update:selectedOptions", [...props.selectedOptions, option]);
}

function unselect(option: Option) {
  emit("unselect", option);
  emit(
    "update:selectedOptions",
    props.selectedOptions.filter(
      (o) => o[props.idValue] !== option[props.idValue],
    ),
  );
}
</script>
